.contactus {
    position: relative;
    z-index: 5;
    padding-top: 60px;
    padding-bottom: 200px;
    color: #000;
    font-weight: 300;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        box-sizing: content-box;
        padding-bottom: percentage(114/1920);
        background: url("#{$root-images}bg.png") no-repeat center bottom;
        background-size: 100% auto;
    }

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url("#{$root-images}main.png") no-repeat center 0;
    }
    @media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, xl) - 1) {
        &:after {
            background-image: url("#{$root-images}main-small.png");
        }
    }
    @media (max-width: 1063px) {
        padding-top: 40px;
        padding-bottom: 100px;
    }
}
.contactus-inner {
    position: relative;
    z-index: 10;
}
.contactus-column {
    padding-right: 8px;
    padding-left: 8px;
    > p {
        min-height: 120px;
    }
    @media (max-width: 1063px) {
        padding-right: 0;
        padding-left: 0;
        > p {
            min-height: 0;
        }
    }
}
.contactus-title {
    position: relative;
    padding-bottom: 1.5rem;
    font-size: r(18);
    font-weight: bold;
    letter-spacing: 0.025em;
    text-transform: uppercase;
    &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 140px;
        height: 2px;
        margin-left: -70px;
        background-color: #000;
    }
}
.contactus-subtitle {
    font-size: r(18);
    font-weight: bold;
    letter-spacing: 0.025em;
}
.contactus-subsubtitle {
    font-size: r(18);
    font-weight: 300;
    letter-spacing: 0.01em;
}
.contactus-lead {
    max-width: 560px;
    margin-right: auto;
    margin-left: auto;
}