// phone
@media (max-width: map-get($grid-breakpoints, md) - 1) {
  .header {
    height: 51px;
    border-bottom: 1px solid #ddd;
    &__top {
      > div {
        height: 50px;
      }
    }
  }
  .nav {
    background-color: $color-white;
    height: auto;
    &__opener {
      font-weight: 300;
      line-height: 20px;
      position: absolute;
      top: 50%;
      left: 350px;
      margin-top: -10px;
      transition: color .3s ease;
      &.is-active {
        color: $color-green-base;
      }
    }
    &__container {
      background-color: $color-white;
      position: fixed;
      top: 51px;
      right: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      padding: 0;
      opacity: 0;
      visibility: hidden;
      transition: visibility 0s ease .3s, opacity .3s ease;
      &.is-active {
        opacity: 1;
        visibility: visible;
        transition-delay: 0s;
      }
      > ul {
        display: block;
        width: 100%;
        height: 100%;
        margin: 0 auto;
        overflow-x: hidden;
        overflow-y: auto;
        > li {
          width: 100%;
          padding: 0;
          margin-left: 0;
          &:nth-child(2) {
            > a {
              padding-left: 20px;
            }
          }
          > a,
          > span {
            color: #000;
            font-size: r(16);
            line-height: 18px;
            padding: 18px 20px;
            border-bottom: 1px solid #f0f0f0;
          }
          &.with-dropdown {
            > span {
              &.is-active {
                background-color: #f8f8f8;
                text-align: center;
                position: relative;
                padding-left: 55px;
                &::after {
                  content: "";
                  background-color: #ddd;
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 55px;
                  height: 55px;
                }
                &::before {
                  right: auto;
                  left: 22px;
                  z-index: 1;
                  margin-top: -12px;
                  @include sprite("arrow-left-black");
                }
              }
              &::before {
                right: 15px;
                margin-top: -7px;
                transform: rotate(0deg)!important;
                @include sprite("arrow-right-black");
              }
            }
            > ul {
              position: relative;
              top: auto;
              right: auto;
              left: auto;
              display: none;
              padding-top: 40px;
              opacity: 1;
              visibility: visible;
              > li {
                float: none;
                width: 100%;
                padding-bottom: 30px;
                > a {
                  padding: 0 20px;
                  margin-bottom: 15px;
                  &:hover {
                    color: $color-green-base;
                    background-color: transparent;
                  }
                }
              }
            }
          }
          &.sub-menu {
            > ul {
              padding: 15px 0;
              > li {
                > a {
                  color: $color-green-base;
                  font-weight: 800;
                  font-size: r(14);
                  text-transform: uppercase;
                  display: block;
                  padding: 15px 60px;
                }
              }
            }
          }
          &:hover {
            > a,
            > span {
              border-bottom-color: #f0f0f0;
            }
          }
        }
      }
    }
  }
  .search {
    &__opener {
      position: relative;
      top: auto;
      right: auto;
      @include sprite("search-green");
    }
  }
  .panel {
    float: right;
    margin-right: -8px;
    > li {
      float: left;
      height: 50px;
      padding: 10px 15px 0;
      border-left: 1px solid #ddd;
      &:first-child {
        border-left: 0;
      }
    }
    .nav-opener {
      text-indent: -9999px;
      position: relative;
      display: block;
      width: 30px;
      height: 30px;
      cursor: pointer;
      > span {
        background-color: $color-green-base;
        position: absolute;
        right: 0;
        left: 0;
        height: 2px;
        &:nth-child(1) {
          top: 4px;
        }
        &:nth-child(2) {
          top: 14px;
        }
        &:nth-child(3) {
          top: 24px;
        }
      }
    }
  }
  .search {
    &__container {
      top: 0;
      right: 0;
      left: auto;
      width: 480px;
      .tt-hint,
      .tt-input {
        height: 60px;
      }
      .tt-suggestion {
        font-size: r(14);
        padding: 10px;
        
        .img {
          flex-basis: 100px;
        }
      }
    }
  }
  .filters {
    text-align: center;
    > li {
      padding: 0 20px;
      margin-right: 0;
    }
  }
  .home {
    .row {
      > article {
        margin-bottom: 8px;
      }
    }
    &__item {
      &--small,
      &--large {
        max-width: 339px;
        margin: 0 auto;
      }
      &--small {
        &:after {
          padding-bottom: 340/342*100%;
        }
      }
      &--large {
        > img {
          left: 50%;
          width: auto;
          max-width: none;
          transform: translateX(-50%);
        }
        &:after {
          padding-bottom: 340/342*100%;
        }
        &:hover {
          > img {
            transform: translateX(-50%) scale(1.07, 1.07);
          }
        }
      }
    }
    h2 {
      font-size: r(16);
      padding-bottom: 0;
      margin-bottom: 0;
      &::before {
        display: none;
      }
    }
    p {
      font-size: r(9);
      display: none;
    }
    &__content-r {
      p {
        display: block!important;
        font-size: r(12);
        max-height: 52px;
        overflow: hidden;
        -ms-text-overflow: ellipsis;
        text-overflow: ellipsis;
      }
	}

  }
  .footer {
    &__content {
      text-align: center;
      margin-bottom: 45px;
    }
  }
  .bottom {
    text-align: center;
  }
  .btn-download {
    margin: 10px auto;
  }
  .modal {
    &__header {
      &-content {
        h2 {
          font-size: r(24);
          padding-bottom: 16px;
          padding-left: 8px;
        }
        .home-i {
          right: 16px;
          bottom: 20px;
        }
      }
    }
    &__button {
      padding-top: 0;
      margin-bottom: 45px;
    }
    &__info {
      text-align: center;
      > div.tags {
        margin-bottom: 10px;
      }
      > span {
        position: relative;
        top: auto;
        left: auto;
        display: table;
        margin: 0 auto 10px;
        transform: translate(0,0);
      }
      > div.added {
        position: relative;
        top: auto;
        right: auto;
      }
    }
  }
  .tabs {
    padding-top: 45px;
    margin-top: 45px;
    &__header {
      > h3 {
        font-size: r(25);
      }
      > p {
        margin-bottom: 45px;
      }
    }
    > div {
      > ul {
        > li {
          > a {
            &::before {
              display: none;
            }
          }
        }
      }
    }
    .tab-content {
      background-color: transparent;
      padding: 16px;
      .row {
        display: none;
      }
      .content {
        background-color: #e7e7e7;
        padding: 16px!important;
      }
    }
    .tab-pane {
      margin-bottom: 10px;
    }
    &__opener {
      color: $color-gray-darken;
      background-color: #e8e8e8;
      font-weight: 700;
      font-size: r(12);
      text-transform: uppercase;
      position: relative;
      padding: 10px 16px;
      cursor: pointer;
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        right: 16px;
        margin-top: -2.5px;
        @include sprite("arrow-bottom-gray");
      }
      &.is-active {
        color: $color-white;
        background-color: $color-gray-darken;
        &::before {
          @include sprite("arrow-top-white");
        }
      }
    }
    &__img {
      background-color: #e7e7e7;
    }
  }
  #personal-car-presentation,
  #car-information-stand,
  #sales-process-guide,
  #knowledge-base,
  #certified-sales-training {
    display: block;
    opacity: 1;
  }
  .slider {
    .content {
      padding: 16px 40px 45px;
    }
    .slick-dots {
      margin-left: 0;
      transform: translateX(-50%);
    }
    .slick-prev {
      width: 25px;
      height: 45px;
      margin-top: -22.5px;
    }
    .slick-next {
      width: 25px;
      height: 45px;
      margin-top: -22.5px;
    }
  }
  .switches {
    float: none;
    display: table;
    width: auto;
    margin: 0 auto;
  }
  .switch--cols {
    margin-right: 25px;
  }
  .home {
    &-i {
      &--large {
        > span {
          transform: scale(0.5,0.5);
        }
      }
    }
    &__item-r {
      width: 135px;
    }
    &__more {
      display: none;
    }
    &__content-r {
      min-height: 91px;
      padding-right: 0;
      padding-left: 16px;
      h2 {
        font-size: r(12);
      }
    }
    &__actions {
      display: none;
    }
  }
  .table-row {
    display: block;
  }
  .table-col {
    display: block;
  }
  .login-page{
        .layer-out{
          h1{
            font-size:25px;
          }
        }
    }
.cross-links {    
min-height: calc( 100vh - 121px ) !important;
}
}

@media (max-width: map-get($grid-breakpoints, sm) - 1) {
.cross-links {   
min-height: calc( 100vh - 151px) !important;
}
}


// phone
@media (max-width: 374px) {
  .home {
    &__item {
      &--small,
      &--large {
        max-width: 280px;
      }
    }
  }
}
