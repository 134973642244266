.logo {
  color: $color-gray-dark;
  @include center("y");
  left: 21px;
  display: block;
  width: 119px;
  > span {
    font-weight: 300;
    line-height: 20px;
    white-space: nowrap;
    position: absolute;
    top: 50%;
    left: 100%;
    padding-left: 8px;
    margin-top: -10px;
  }
  &:hover {
    color: $color-gray-dark;
  }
}
.header {
  background-color: $color-white;
  position: relative;
  z-index: 100;
  &__top {
    > div {
      position: relative;
      height: 60px;
    }
    &.scrolled {
      margin-bottom: 60px;
    }
  }
}
.menu {
  position: absolute;
  top: 0;
  right: 20px;
  > ul {
    > li {
      float: left;
      margin-left: 45px;
      > a {
        color: $color-black;
        font-weight: 400;
        font-size: r(14);
        line-height: 60px;
        text-decoration: none;
        display: block;
        transition: color .3s ease;
        &:hover {
          color: $color-green-base;
        }
      }
	  &.active {
        a {
          color: $color-green-base;
        }
	  }
      &:first-child {
        margin-left: 0;
      }
      &.architecture {
        margin-right: -20px;
        margin-left: 60px;
        > a {
          color: $color-white;
          background-color: $color-green-base;
          font-size: r(14);
          text-align: center;
          position: relative;
          width: 110px;
          transition: background-color .3s ease;
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: -18px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 38px 18px 22px 0;
            border-color: transparent $color-green-base transparent transparent;
            transition: all .3s ease;
          }
          &:hover {
            background-color: darken($color-green-base, 5%);
            &::before {
              border-width: 15px 18px 45px 0;
              border-color: transparent darken($color-green-base, 5%) transparent transparent;
            }
          }
        }
      }
      &.login {
        position: relative;
        padding-left: 30px;
        &::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          margin-top: -11.5px;
          @include sprite("login");
        }
        > span {
          font-weight: 300;
          font-size: r(10);
          line-height: 12px;
          display: block;
          padding-top: 18px;
          padding-bottom: 18px;
          > a {
            color: $color-black;
            text-decoration: underline;
            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}
.nav {
  background-color: $color-black;
  height: 60px;
  &.scrolled {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    width: 100%
  }
  &__container {
    > ul {
      > li {
        float: left;
        margin-left: 40px;
        &:first-child,
        &:nth-child(2) {
          margin-left: 0;
        }
        &:nth-child(2) {
          > a {
            padding-left: 0;
          }
        }
        > a,
        > span {
          color: $color-white;
          font-size: r(14);
          line-height: 56px;
          text-decoration: none;
          white-space: nowrap;
          display: block;
          padding: 0;
          border-bottom: 4px solid $color-black;
          transition: border .3s ease;
          cursor: pointer;
        }
        &.is-active {
          > a {
            color: $color-green-base;
          }
        }
        &.with-dropdown {
          position: relative;
          > span {
            padding-right: 20px;
            &::before {
              content: "";
              position: absolute;
              top: 50%;
              right: 0;
              margin-top: -3.5px;
              @include sprite("arrow-bottom-white");
              transition: transform .3s ease;
            }
          }
          > ul {
            background-color: $color-white;
            position: absolute;
            top: 100%;
            left: 0;
            z-index: 9999;
            opacity: 0;
            visibility: hidden;
            box-shadow: 0px 0px 15px 0px rgba(50, 50, 50, 0.1);
            > li {
              > a {
                color: $color-black;
                font-weight: 300;
                font-size: r(14);
                white-space: nowrap;
                text-decoration: none;
                display: block;
                padding: 10px 20px;
                transition: color, background-color .3s ease;
                &:hover {
                  color: $color-white;
                  background-color: $color-green-base;
                }
              }
            }
          }
          &:hover {
            > span {
              &::before {
                transform: rotate(180deg);
              }
            }
            > ul {
              opacity: 1;
              visibility: visible;
              transition-delay: 0s;
            }
          }
        }
        &:hover {
          > a,
          > span {
            border-bottom-color: $color-green-base;
          }
        }
        &.digital-showroom {
          &:hover {
            > a,
            > span {
              border-bottom-color: #15af97;
            }
          }
        }
        &.sales-support-tool {
          &:hover {
            > a,
            > span {
              border-bottom-color: #a2c617;
            }
          }
        }
        &.video-wall {
          &:hover {
            > a,
            > span {
              border-bottom-color: #ea5167;
            }
          }
        }
        &.interactive-sales-tools {
          &:hover {
            > a,
            > span {
              border-bottom-color: #0090d7;
            }
          }
        }
        &.prototypes-ideas {
          &:hover {
            > a,
            > span {
              border-bottom-color: #e5d1a2;
            }
          }
        }
      }
    }
  }
}
.search {
  &__opener {
    text-indent: -9999px;
    position: absolute;
    top: 76px;
    right: 20px;
    display: block;
    cursor: pointer;
    @include sprite("search");
    visibility: visible;
    opacity: 1;
    transition: visibility 0s ease .3s, opacity .3s ease;
    &.is-active {
      opacity: 0;
      visibility: hidden;
    }
  }
  &__container {
    background-color: $color-white;
    position: absolute;
    top: 120px;
    right: 50px;
    left: 50px;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    box-shadow: 0px 0px 15px 0px rgba(50, 50, 50, 0.1);
    transition: visibility 0s ease .3s, opacity .3s ease;
    .twitter-typeahead, .tt-hint, .tt-input, .tt-menu { width: 100%; }
    .tt-hint,
    .tt-input {
      line-height: 26px;
      width: 100%;
      height: 66px;
      padding: 20px 85px;
      border: 0;
      outline: none;
      box-shadow: none;
      appearance: none;
    }
    .tt-menu {
      position: relative!important;
      right: 0;
      border-top: 1px solid #9d9d9c;
    }
    .tt-suggestion {
      font-size: r(24);
      line-height: 1.2;
      padding: 10px 85px;
      color: $color-black;
      display: flex;
      align-items: center;
      .img {
        position: relative;
        flex-basis: 130px;
        flex-shrink: 0;
        margin-right: 10px;
        .img-fluid {
          max-height: 65px;
          margin: 0 auto;
          display: block;
        }
      }
      .tt-i {
        position: absolute;
        top: 50%;
        left: 50%;
        text-indent: -9999px;
        transform: translate(-50%, -50%);
        &__news {
          @include sprite("new");
        }
        &__images {
          @include sprite("photo");
        }
        &__doc {
          @include sprite("doc");
        }
        &__video {
          @include sprite("play");
        }
      }
      .tt-title {
        display: block;
      }
      .tt-hinta {
        display: block;
        font-style: italic;
        font-size: r(14);
      }
    }
    &.is-active {
      opacity: 1;
      visibility: visible;
      transition-delay: 0s;
    }
  }
  &__submit {
    text-indent: -9999px;
    position: absolute;
    top: 18px;
    left: 21px;
    z-index: 1;
    display: block;
    cursor: pointer;
    @include sprite("search-gray");
  }
  &__close {
    text-indent: -9999px;
    position: absolute;
    top: 18px;
    right: 15px;
    z-index: 1;
    display: block;
    cursor: pointer;
    transition: transform .5s ease;
    transform: rotate(0deg);
    @include sprite("close");
    &:hover {
      transform: rotate(180deg);
    }
  }
}
