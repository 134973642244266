.ourvisions {
    position: relative;
    z-index: 5;
    height: 680px;
    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: 20;
        width: 100%;
        height: 100%;
        background: url("#{$root-images}main.png") no-repeat center 0;
    }
    @media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, xl) - 1) {
        &:after {
            background-image: url("#{$root-images}main-small.png");
        }
    }
    @media (max-width: 1063px) {
        height: 630px - 60px;
    }
    @media (max-width: 768px) {
        height: 630px - 60px - 120px;
    }
    @media (min-width: 1064px) and (max-height: 900px) {
        height: 600px;
    }
    @media (min-width: 1064px) and (max-height: 800px) {
        height: 540px;
    }
    @media (min-width: 1064px) and (max-height: 700px) {
        height: 500px;
    }
    @media (min-width: 1440px) and (min-height: 900px) {
        height: 540px;
    }
}
.ourvisions-carousel {
    height: 100%;
    .slick-list {
        height: 100%;
        overflow: inherit;
    }
    .slick-track {
        width: 100% !important;
        height: 100%;
    }
    .slick-slide {
        position: absolute !important;
        left: 0 !important;
        height: 100%;
        > div {
            height: 100%;
        }
    }
    .slick-dots {
        position: absolute;
        top: 200px;
        left: 0;
        z-index: 30;
        width: 100%;
        text-align: center;
        > li {
            display: inline-block;
            margin-right: 8px;
            margin-left: 8px;
            > button {
                width: 12px;
                height: 12px;
                padding: 0;
                margin: 0;
                border: 0;
                border-radius: 50%;
                background-color: #bfbfbf;
                text-indent: -9999px;
                &:focus {
                    outline: 0;
                }
            }
            &.slick-active {
                > button {
                    background-color: #41a62a;
                }
            }
        }
    }
    @media (max-width: 1063px) {
        .slick-dots {
            top: 160px;
        }
    }
    @media (max-width: 767px) {
        .slick-dots {
            top: 200px;
        }
    }
    @media (max-width: 414px) {
        .slick-dots {
            top: 220px;
        }
    }
    @media (max-width: 375px) {
        .slick-dots {
            top: 240px;
        }
    }
    @media (max-width: 320px) {
        .slick-dots {
            top: 280px;
        }
    }
    @media (min-width: 1064px) and (max-height: 800px) {
        .slick-dots {
            top: 180px;
        }
    }
    @media (min-width: 1064px) and (max-height: 700px) {
        .slick-dots {
            top: 140px;
        }
    }
}
.ourvisions-item {
    position: relative;
    height: 100%;
    padding-top: 60px;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        width: 100%;
        height: 100%;
        box-sizing: content-box;
        padding-bottom: percentage(114/1920);
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: 100% auto;
    }
    @media (max-width: 1063px) {
        padding-top: 40px;
    }
    @media (max-width: 414px) {
        &:before {
            background-size: 150% auto;
        }
    }
    @media (min-width: 1064px) and (max-height: 800px) {
        padding-top: 40px;
    }
    @media (min-width: 1064px) and (max-height: 700px) {
        padding-top: 20px;
    }
}
.ourvisions-item--first {
    &:before {
        background-image: url("#{$root-images}slide-1.png")
    }
    @media (min-width: 1440px) {
        &:before {
            background-image: url("#{$root-images}slide-1@1920.png");
        }
    }
}
.ourvisions-item--second {
    &:before {
        background-image: url("#{$root-images}slide-2.png");
    }
    @media (min-width: 1440px) {
        &:before {
            background-image: url("#{$root-images}slide-2@1920.png");
        }
    }
}
.ourvisions-item--third {
    &:before {
        background-image: url("#{$root-images}slide-3.png");
    }
    @media (min-width: 1440px) {
        &:before {
            background-image: url("#{$root-images}slide-3@1920.png");
        }
    }
}
.ourvisions-claim {
    position: relative;
    z-index: 30;
    font-size: r(18);
    letter-spacing: 0.025em;
    line-height: 1.25;
    @media (max-width: 1063px) {
        font-size: r(16);
        > br {
            display: none;
        }
    }
    @media (min-width: 1064px) and (max-height: 700px) {
        font-size: r(16);
    }
}