// tablet landscape
@media (min-width: map-get($grid-breakpoints, lg)) and (max-width: map-get($grid-breakpoints, xl) - 1) {
  .nav {
    &__container {
      > ul {
        > li:last-child {
          display: none!important;
        }
        > li.with-dropdown {
          > ul {
            display: block!important;
          }
        }
      }
    }
  }
  .home {
    &__content-r {
      padding-right: 316px;
    }
    &__actions {
      width: 300px;
    }
  }
}
