// phone portrait
@media (max-width: map-get($grid-breakpoints, sm) - 1) {
  body {
    min-width: 320px;
  }
  .search {
    &__container {
      &--mobile {
        background-color: $color-white;
        position: relative;
        box-shadow: 0px 0px 15px 0px rgba(50, 50, 50, 0.1);
        .twitter-typeahead, .tt-hint, .tt-input, .tt-menu { width: 100%; }
        .tt-hint,
        .tt-input {
          line-height: 25px;
          width: 100%;
          height: 45px;
          padding: 10px 65px 10px 20px;
          border: 0;
          outline: none;
          box-shadow: none;
          appearance: none;
        }
        .tt-menu {
          position: relative!important;
          right: 0;
          border-top: 1px solid #9d9d9c;
        }
        .tt-suggestion {
          font-size: r(16);
          padding: 10px 20px;
          color: $color-black;
          display: block;

          .img {
            margin-bottom: 10px;
          }

          > span {
            display: block;
          }
        }
        .search__submit {
          top: 10px;
          right: 20px;
          left: auto;
        }
      }
    }
  }
  .modal {
    &__header {
      height: 200px;
      overflow: hidden;
      > img {
        @include center("both");
        width: auto;
        max-width: none;
      }
    }
    &__list {
      > div {
        padding-right: 16px!important;
        padding-left: 16px!important;
        &:nth-child(3) {
          &::before {
            display: none;
          }
        }
      }
    }
    &__at-right {
      max-width: 100%;
      padding-top: 16px;
      padding-right: 16px!important;
      padding-left: 16px!important;
    }
  }
  .alt-footer{
    span,a{
      margin-left:0px !important;
      margin-right:0px !important;
    }
  }
}
