.modal__title {
  font-weight: 700;
  font-size: r(25);
  line-height: 1.2;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 40px;
}

.modal__fullimage {
	display: block;
	padding-bottom: 0px;
}

.tabs-table {
  .nav {
    background-color: $color-white;
    > li {
      width: 50%;
    }
    a {
      color: $color-gray-lighten;
      font-size: r(13);
      line-height: 60px;
      text-transform: uppercase;
      text-decoration: none;
      text-align: center;
      position: relative;
      vertical-align: middle;
      display: table;
      width: 100%;
      height: 60px;
      padding: 0 16px;
      &.active {
          background-color: #7ec9f1;
		  color: #FFF;
      }
      &:hover {
		  background-color: darken(#7ec9f1, 3%);
		  color: #FFF;
          text-decoration: none;
      }
    }
    &.nav-tabs__7 {
      background-color: #e8e8e8;
      > li {
        a {
			&.active {
			  background-color: #7ec9f1;
			  color: #FFF;
			}
			&:hover {
				background-color: darken(#7ec9f1, 3%);
				color: #FFF;
				text-decoration: none;
			}
        }
      }
    }
  }
  .tab-content {
    background-color: #e8e8e8;
    padding: 16px;
    table {
      width: 100%;
      td {
        font-weight: 300;
        font-size: r(15);
        width: calc(100% - 100px);
        padding-top: 5px;
        padding-right: 0;
        padding-bottom: 5px;
      }
      td:first-child {
        width: 110px;
        padding-right: 16px;
      }
    }
    &.tab-content__7 {
      background-color: #f5f5f5;
      padding-top: 32px;
    }
    .modal__fullimage {
      padding-bottom: 0;
    }
  }
}
