.entry-content {
  color: $color-gray-lighten;
  font-weight: 300;
  h2 {
    font-weight: 700;
    font-size: r(21);
    line-height: 1.2;
    margin-top: 25px;
    margin-bottom: 25px;
  }
  h3 {
    font-weight: 700;
    font-size: r(18);
    line-height: 1.2;
    margin-top: 25px;
    margin-bottom: 25px;
  }
  p {
    margin: 25px 0;
  }
  ul ul,
  ul ol,
  ol ul,
  ol ol {
    margin: 0;
  }
  ol {
    counter-reset: li;
    margin: 25px 0;
    > li {
      position: relative;
      padding-left: 25px;
      &::before {
        content: counter(li)'.';
				counter-increment: li;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
  ul {
    > li {
      position: relative;
      padding-left: 12px;
      &::before {
        content: "";
        position: absolute;
        top: 8px;
        left: 0;
        @include sprite("arrow-right");
      }
    }
  }
  a {
    color: $color-green-base;
  }
  > :first-child {
    margin-top: 0;
  }
  > :last-child {
    margin-bottom: 0;
  }
}
