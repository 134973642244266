$sprite: (
	arrow-bottom-black: (
		offset-x: -168px,
		offset-y: 0px,
		width: 23px,
		height: 33px,
		total-width: 250px,
		total-height: 232px,
		image: 'images/bg/sprite.png'
	),
	arrow-bottom-gray: (
		offset-x: -118px,
		offset-y: -98px,
		width: 11px,
		height: 5px,
		total-width: 250px,
		total-height: 232px,
		image: 'images/bg/sprite.png'
	),
	arrow-bottom-white: (
		offset-x: -174px,
		offset-y: -191px,
		width: 12px,
		height: 6px,
		total-width: 250px,
		total-height: 232px,
		image: 'images/bg/sprite.png'
	),
	arrow-left-black: (
		offset-x: -201px,
		offset-y: -41px,
		width: 11px,
		height: 24px,
		total-width: 250px,
		total-height: 232px,
		image: 'images/bg/sprite.png'
	),
	arrow-left-white: (
		offset-x: -201px,
		offset-y: -103px,
		width: 9px,
		height: 18px,
		total-width: 250px,
		total-height: 232px,
		image: 'images/bg/sprite.png'
	),
	arrow-right-black: (
		offset-x: -182px,
		offset-y: -154px,
		width: 7px,
		height: 14px,
		total-width: 250px,
		total-height: 232px,
		image: 'images/bg/sprite.png'
	),
	arrow-right-white: (
		offset-x: -201px,
		offset-y: -75px,
		width: 9px,
		height: 18px,
		total-width: 250px,
		total-height: 232px,
		image: 'images/bg/sprite.png'
	),
	arrow-right: (
		offset-x: -36px,
		offset-y: -224px,
		width: 4px,
		height: 7px,
		total-width: 250px,
		total-height: 232px,
		image: 'images/bg/sprite.png'
	),
	arrow-top-white: (
		offset-x: -201px,
		offset-y: -176px,
		width: 11px,
		height: 5px,
		total-width: 250px,
		total-height: 232px,
		image: 'images/bg/sprite.png'
	),
	back: (
		offset-x: -141px,
		offset-y: -154px,
		width: 31px,
		height: 17px,
		total-width: 250px,
		total-height: 232px,
		image: 'images/bg/sprite.png'
	),
	close-green-small: (
		offset-x: -168px,
		offset-y: -125px,
		width: 18px,
		height: 18px,
		total-width: 250px,
		total-height: 232px,
		image: 'images/bg/sprite.png'
	),
	close-white-small: (
		offset-x: -104px,
		offset-y: -191px,
		width: 18px,
		height: 18px,
		total-width: 250px,
		total-height: 232px,
		image: 'images/bg/sprite.png'
	),
	close-white: (
		offset-x: -123px,
		offset-y: -113px,
		width: 28px,
		height: 28px,
		total-width: 250px,
		total-height: 232px,
		image: 'images/bg/sprite.png'
	),
	close: (
		offset-x: -85px,
		offset-y: -113px,
		width: 28px,
		height: 28px,
		total-width: 250px,
		total-height: 232px,
		image: 'images/bg/sprite.png'
	),
	cols-active: (
		offset-x: -18px,
		offset-y: -224px,
		width: 8px,
		height: 8px,
		total-width: 250px,
		total-height: 232px,
		image: 'images/bg/sprite.png'
	),
	cols: (
		offset-x: 0px,
		offset-y: -224px,
		width: 8px,
		height: 8px,
		total-width: 250px,
		total-height: 232px,
		image: 'images/bg/sprite.png'
	),
	doc-large: (
		offset-x: -118px,
		offset-y: 0px,
		width: 40px,
		height: 47px,
		total-width: 250px,
		total-height: 232px,
		image: 'images/bg/sprite.png'
	),
	doc: (
		offset-x: -228px,
		offset-y: -165px,
		width: 19px,
		height: 22px,
		total-width: 250px,
		total-height: 232px,
		image: 'images/bg/sprite.png'
	),
	download-backup: (
		offset-x: -201px,
		offset-y: -154px,
		width: 9px,
		height: 12px,
		total-width: 250px,
		total-height: 232px,
		image: 'images/bg/sprite.png'
	),
	download-large: (
		offset-x: -168px,
		offset-y: -43px,
		width: 22px,
		height: 31px,
		total-width: 250px,
		total-height: 232px,
		image: 'images/bg/sprite.png'
	),
	download: (
		offset-x: -201px,
		offset-y: -131px,
		width: 9px,
		height: 13px,
		total-width: 250px,
		total-height: 232px,
		image: 'images/bg/sprite.png'
	),
	indd: (
		offset-x: -228px,
		offset-y: -33px,
		width: 20px,
		height: 23px,
		total-width: 250px,
		total-height: 232px,
		image: 'images/bg/sprite.png'
	),
	left-green: (
		offset-x: -201px,
		offset-y: 0px,
		width: 17px,
		height: 31px,
		total-width: 250px,
		total-height: 232px,
		image: 'images/bg/sprite.png'
	),
	login: (
		offset-x: 0px,
		offset-y: -191px,
		width: 23px,
		height: 23px,
		total-width: 250px,
		total-height: 232px,
		image: 'images/bg/sprite.png'
	),
	new-large: (
		offset-x: -62px,
		offset-y: 0px,
		width: 46px,
		height: 52px,
		total-width: 250px,
		total-height: 232px,
		image: 'images/bg/sprite.png'
	),
	new: (
		offset-x: -228px,
		offset-y: -99px,
		width: 20px,
		height: 23px,
		total-width: 250px,
		total-height: 232px,
		image: 'images/bg/sprite.png'
	),
	pdf: (
		offset-x: -228px,
		offset-y: -132px,
		width: 20px,
		height: 23px,
		total-width: 250px,
		total-height: 232px,
		image: 'images/bg/sprite.png'
	),
	photo-gray: (
		offset-x: -69px,
		offset-y: -191px,
		width: 25px,
		height: 20px,
		total-width: 250px,
		total-height: 232px,
		image: 'images/bg/sprite.png'
	),
	photo-large: (
		offset-x: 0px,
		offset-y: -62px,
		width: 53px,
		height: 41px,
		total-width: 250px,
		total-height: 232px,
		image: 'images/bg/sprite.png'
	),
	photo: (
		offset-x: -33px,
		offset-y: -191px,
		width: 26px,
		height: 20px,
		total-width: 250px,
		total-height: 232px,
		image: 'images/bg/sprite.png'
	),
	play-gray: (
		offset-x: -107px,
		offset-y: -154px,
		width: 24px,
		height: 24px,
		total-width: 250px,
		total-height: 232px,
		image: 'images/bg/sprite.png'
	),
	play-large: (
		offset-x: 0px,
		offset-y: 0px,
		width: 52px,
		height: 52px,
		total-width: 250px,
		total-height: 232px,
		image: 'images/bg/sprite.png'
	),
	play: (
		offset-x: -228px,
		offset-y: 0px,
		width: 22px,
		height: 23px,
		total-width: 250px,
		total-height: 232px,
		image: 'images/bg/sprite.png'
	),
	printer-machine: (
		offset-x: 0px,
		offset-y: -113px,
		width: 35px,
		height: 31px,
		total-width: 250px,
		total-height: 232px,
		image: 'images/bg/sprite.png'
	),
	printer-paper: (
		offset-x: -63px,
		offset-y: -62px,
		width: 35px,
		height: 31px,
		total-width: 250px,
		total-height: 232px,
		image: 'images/bg/sprite.png'
	),
	printer: (
		offset-x: -118px,
		offset-y: -57px,
		width: 35px,
		height: 31px,
		total-width: 250px,
		total-height: 232px,
		image: 'images/bg/sprite.png'
	),
	right-green: (
		offset-x: -168px,
		offset-y: -84px,
		width: 17px,
		height: 31px,
		total-width: 250px,
		total-height: 232px,
		image: 'images/bg/sprite.png'
	),
	rows-active: (
		offset-x: -132px,
		offset-y: -191px,
		width: 11px,
		height: 8px,
		total-width: 250px,
		total-height: 232px,
		image: 'images/bg/sprite.png'
	),
	rows: (
		offset-x: -153px,
		offset-y: -191px,
		width: 11px,
		height: 8px,
		total-width: 250px,
		total-height: 232px,
		image: 'images/bg/sprite.png'
	),
	search-gray: (
		offset-x: -72px,
		offset-y: -154px,
		width: 25px,
		height: 27px,
		total-width: 250px,
		total-height: 232px,
		image: 'images/bg/sprite.png'
	),
	search-green: (
		offset-x: -36px,
		offset-y: -154px,
		width: 26px,
		height: 27px,
		total-width: 250px,
		total-height: 232px,
		image: 'images/bg/sprite.png'
	),
	search: (
		offset-x: 0px,
		offset-y: -154px,
		width: 26px,
		height: 27px,
		total-width: 250px,
		total-height: 232px,
		image: 'images/bg/sprite.png'
	),
	time: (
		offset-x: -228px,
		offset-y: -197px,
		width: 17px,
		height: 17px,
		total-width: 250px,
		total-height: 232px,
		image: 'images/bg/sprite.png'
	),
	word: (
		offset-x: -228px,
		offset-y: -66px,
		width: 20px,
		height: 23px,
		total-width: 250px,
		total-height: 232px,
		image: 'images/bg/sprite.png'
	),
	zoom: (
		offset-x: -45px,
		offset-y: -113px,
		width: 30px,
		height: 30px,
		total-width: 250px,
		total-height: 232px,
		image: 'images/bg/sprite.png'
	),
);


@mixin sprite-size($image) {
	background-size: map-get(map-get($sprite, $image), 'total-width') map-get(map-get($sprite, $image), 'total-height');
}

@mixin sprite-width($image) {
	width: map-get(map-get($sprite, $image), 'width');
}

@mixin sprite-height($image) {
	height: map-get(map-get($sprite, $image), 'height');
}

@mixin sprite-position($image) {
	background-position: map-get(map-get($sprite, $image), 'offset-x') map-get(map-get($sprite, $image), 'offset-y');
}

@mixin sprite-image($image) {
	background-image: url(map-get(map-get($sprite, $image), 'image'));
}

@mixin sprite-image-retina($image) {
	$image-path: map-get(map-get($sprite, $image), 'image');
	$image-path-retina: str-slice($image-path, 1, str-index($image-path, '.png') - 1) + '@2x.png';

	background-image: url($image-path-retina);
}

@mixin sprite($image, $size: true) {
	@include sprite-image($image);
	@include sprite-position($image);
	background-repeat: no-repeat;

	@if $size {
		@include sprite-width($image);
		@include sprite-height($image);
	}

	@media
		only screen and (-webkit-min-device-pixel-ratio: 2),
		only screen and (   min--moz-device-pixel-ratio: 2),
		only screen and (     -o-min-device-pixel-ratio: 2/1),
		only screen and (        min-device-pixel-ratio: 2),
		only screen and (                min-resolution: 192dpi),
		only screen and (                min-resolution: 2dppx) {

		@include sprite-size($image);
		@include sprite-image-retina($image);

	}
}

