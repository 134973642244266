html {
  font-family: Arial;
	
  &.nav-is-active {
    max-width: 100%;
    max-height: 100%;
    position: fixed;
    width: 100%;
    overflow: hidden;
  }
}
body {
  color: $color-black;
  font-family: 'SKODA Next', Arial;
  -webkit-font-smoothing: antialiased;
  &.nav-is-active {
    max-width: 100%;
    max-height: 100%;
    position: fixed;
    width: 100%;
    overflow: hidden;
  }
}

ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

img {
	display: block;
	-ms-interpolation-mode: bicubic;
	opacity: 1;
	transition: all .3s ease;
	&[data-src] {
		opacity: 0;
	}
}

.img-holder {
	position: relative;
	&::after{
		content: '';
		display: block;
	}
	img {
		max-height: 100%;
		max-width: 100%;
	}
}

strong,
b {
  font-weight: 700;
}

.page {
  max-width: 1920px;
  margin-right: auto;
  margin-left: auto;
}

@font-face {
    font-family: 'SKODA Next';
    src: url('#{$root-fonts}SKODANext-Regular.eot');
    src: url('#{$root-fonts}SKODANext-Regular.eot?#iefix') format('embedded-opentype'),
        url('#{$root-fonts}SKODANext-Regular.woff2') format('woff2'),
        url('#{$root-fonts}SKODANext-Regular.woff') format('woff'),
        url('#{$root-fonts}SKODANext-Regular.ttf') format('truetype'),
        url('#{$root-fonts}SKODANext-Regular.svg#SKODANext-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'SKODA Next';
    src: url('#{$root-fonts}SKODANext-Bold.eot');
    src: url('#{$root-fonts}SKODANext-Bold.eot?#iefix') format('embedded-opentype'),
        url('#{$root-fonts}SKODANext-Bold.woff2') format('woff2'),
        url('#{$root-fonts}SKODANext-Bold.woff') format('woff'),
        url('#{$root-fonts}SKODANext-Bold.ttf') format('truetype'),
        url('#{$root-fonts}SKODANext-Bold.svg#SKODANext-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'SKODA Next';
    src: url('#{$root-fonts}SKODANext-Light.eot');
    src: url('#{$root-fonts}SKODANext-Light.eot?#iefix') format('embedded-opentype'),
        url('#{$root-fonts}SKODANext-Light.woff2') format('woff2'),
        url('#{$root-fonts}SKODANext-Light.woff') format('woff'),
        url('#{$root-fonts}SKODANext-Light.ttf') format('truetype'),
        url('#{$root-fonts}SKODANext-Light.svg#SKODANext-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'SKODA Next';
    src: url('#{$root-fonts}SKODANext-Black.eot');
    src: url('#{$root-fonts}SKODANext-Black.eot?#iefix') format('embedded-opentype'),
        url('#{$root-fonts}SKODANext-Black.woff2') format('woff2'),
        url('#{$root-fonts}SKODANext-Black.woff') format('woff'),
        url('#{$root-fonts}SKODANext-Black.ttf') format('truetype'),
        url('#{$root-fonts}SKODANext-Black.svg#SKODANext-Black') format('svg');
    font-weight: 900;
    font-style: normal;
}
