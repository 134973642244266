//http://chir.ag/projects/name-that-color/

/* colors */
$color-white: #fff; // white
$color-black: #000; // black

$color-gray-base: #9d9d9c;
$color-gray-dark: #010000;
$color-gray-darken: #4a4a4a;
$color-gray-light: #9d9d9d;
$color-gray-lighten: #777777;
$color-green-base: #41a52a;
$color-yellow-base: #ffdf43;

$color-modal-gray-base: #cecece;
