/*root*/
$root-images: 'images/bg/';
$root-fonts: 'fonts/';

/* base font-size */
$default-font-size: 16; //px

@function r($pixels,
$context: $default-font-size) {
	@return #{$pixels/$context}rem;
}
