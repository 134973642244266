// tablet
@media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, xl) - 1) {
  .nav {
    &__container {
      > ul {
        > li {
          &:first-child {
            display: none!important;
          }
        }
      }
    }
  }
  .main {
    &--home {
      background-image: url("#{$root-images}main-small.jpg");
    }
  }
  .search {
    &__container {
      .tt-suggestion {
        font-size: r(16);
        padding: 10px 20px;
        
        .img {
          flex-basis: 100px;
        }
      }
    }
  }
}

@media (max-width: 1063px) {
  .home__item--small {
    &:after {
      padding-bottom: 224/225*100%;
    }
  }

  .home__item--large {
    &:after {
      padding-bottom: 224/467*100%;
    }
  }
  .home__item--third {
    &:after {
      padding-bottom: 340/600*100%;
    }
  }

   .overlay-layer{
        background-color: rgba(36, 36, 36, 0.9) !important;
      }

      .showroom-experience,.emobility-age {
        border-bottom: 1px solid rgb(100,100,100) !important;

        &:after{
          display:none;
        }
      }
   .layer-out{
    max-height: none !important;
    height: auto !important;
   }   
   .login-page{
        .layer-out{
          h1{
            font-size:38px;
          }
        }
    }

}
