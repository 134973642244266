// tablet portrait
@media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, lg) - 1) {
  .header {
    height: 61px;
    border-bottom: 1px solid #ddd;
  }
  .nav {
    background-color: $color-white;
    height: auto;
    &__opener {
      font-weight: 300;
      line-height: 20px;
      position: absolute;
      top: 50%;
      left: 350px;
      margin-top: -10px;
      transition: color .3s ease;
      &.is-active {
        color: $color-green-base;
      }
    }
    &__container {
      background-color: $color-white;
      position: fixed;
      top: 61px;
      right: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      padding: 0;
      opacity: 0;
      visibility: hidden;
      transition: visibility 0s ease .3s, opacity .3s ease;
      &.is-active {
        opacity: 1;
        visibility: visible;
        transition-delay: 0s;
      }
      > ul {
        display: block;
        width: 100%;
        height: 100%;
        margin: 0 auto;
        overflow-x: hidden;
        overflow-y: auto;
        > li {
          width: 100%;
          padding: 0;
          margin-left: 0;
          &:nth-child(2) {
            > a {
              padding-left: 20px;
            }
          }
          > a,
          > span {
            color: #000;
            font-size: r(16);
            line-height: 18px;
            padding: 18px 20px;
            border-bottom: 1px solid #f0f0f0;
          }
          &.with-dropdown {
            > span {
              &.is-active {
                background-color: #f8f8f8;
                text-align: center;
                position: relative;
                padding-left: 55px;
                &::after {
                  content: "";
                  background-color: #ddd;
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 55px;
                  height: 55px;
                }
                &::before {
                  right: auto;
                  left: 22px;
                  z-index: 1;
                  margin-top: -12px;
                  @include sprite("arrow-left-black");
                }
              }
              &::before {
                right: 15px;
                margin-top: -7px;
                transform: rotate(0deg)!important;
                @include sprite("arrow-right-black");
              }
            }
            > ul {
              position: relative;
              top: auto;
              right: auto;
              left: auto;
              display: none;
              padding-top: 40px;
              opacity: 1;
              visibility: visible;
              > li {
                float: left;
                width: 50%;
                padding-bottom: 30px;
                > a {
                  padding: 0 20px;
                  margin-bottom: 15px;
                  &:hover {
                    color: $color-green-base;
                    background-color: transparent;
                  }
                }
              }
            }
          }
          &.sub-menu {
            > ul {
              padding: 15px 0;
              > li {
                > a {
                  color: $color-green-base;
                  font-weight: 800;
                  font-size: r(14);
                  text-transform: uppercase;
                  display: block;
                  padding: 15px 60px;
                }
              }
            }
          }
          &:hover {
            > a,
            > span {
              border-bottom-color: #f0f0f0;
            }
          }
        }
      }
    }
  }
  .search {
    &__opener {
      position: relative;
      top: auto;
      right: auto;
      @include sprite("search-green");
    }
  }
  .panel {
    float: right;
    margin-right: -8px;
    > li {
      float: left;
      height: 60px;
      padding: 15px 15px 0;
      border-left: 1px solid #f0f0f0;
      &:first-child {
        border-left: 0;
      }
    }
    .nav-opener {
      text-indent: -9999px;
      position: relative;
      display: block;
      width: 30px;
      height: 30px;
      cursor: pointer;
      > span {
        background-color: $color-green-base;
        position: absolute;
        right: 0;
        left: 0;
        height: 2px;
        &:nth-child(1) {
          top: 4px;
        }
        &:nth-child(2) {
          top: 14px;
        }
        &:nth-child(3) {
          top: 24px;
        }
      }
    }
    .login {
      position: relative;
      padding-left: 45px;
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 15px;
        margin-top: -11.5px;
        @include sprite("login");
      }
      > span {
        font-weight: 300;
        font-size: r(10);
        line-height: 12px;
        display: block;
        padding-top: 5px;
        > a {
          color: $color-black;
          text-decoration: underline;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
  .search {
    &__container {
      top: 0;
      right: 0;
      left: auto;
      width: 480px;
      .tt-hint,
      .tt-input {
        height: 60px;
      }
    }
  }
  .home {
    h2 {
      font-size: r(16);
      padding-bottom: 0;
      margin-bottom: 0;
      &::before {
        display: none;
      }
    }
    p {
      display: none;
    }
  }
  .footer {
    &__content {
      text-align: center;
      margin-bottom: 45px;
    }
  }
  .tabs {
    .content {
      padding: 16px!important;
    }
  }
  .slider {
    .content {
      padding: 16px 16px 45px;
    }
    .slick-dots {
      margin-left: 0;
      transform: translateX(-50%);
    }
  }
  .home {
    &__content-r {
      padding-right: 266px;
      padding-left: 16px;

      p {
        font-size: r(12);
        max-height: 52px;
        overflow: hidden;
        -ms-text-overflow: ellipsis;
        text-overflow: ellipsis;
        display: block;
      }
    }
    &__more {
      left: 16px;
    }
    &__actions {
      width: 250px;
      padding-left: 16px;
    }
  }
}
