// phone landscape
@media (min-width: map-get($grid-breakpoints, sm)) and (max-width: map-get($grid-breakpoints, md) - 1) {
  .nav {
    &__container {
      > ul {
        > li:first-child {
          display: none!important;
        }
      }
    }
  }
  .panel {
    .login {
      position: relative;
      padding-left: 45px;
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 15px;
        margin-top: -11.5px;
        @include sprite("login");
      }
      > span {
        font-weight: 300;
        font-size: r(10);
        line-height: 12px;
        display: block;
        padding-top: 5px;
        > a {
          color: $color-black;
          text-decoration: underline;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
  .search {
    &__container {
      width: 430px;
      .tt-hint,
      .tt-input {
        height: 50px;
      }
    }
    &__submit,
    &__close {
      top: 12px;
    }
  }
  .modal {
    &__list {
      > div {
        &:nth-child(3) {
          &::before {
            display: none;
          }
        }
      }
    }
    &__at-right {
      padding-right: 30px!important;
      padding-left: 30px!important;
    }
  }
}

@media (max-width: map-get($grid-breakpoints, sm) - 1) {
  .cross-links {   
    min-height: calc( 100vh - 151px) !important;
  }
}

