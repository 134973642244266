.main {
  &--home {
    background: url("#{$root-images}main.jpg") no-repeat center 0;
  }
}
.home {
  h1 {
    color: $color-gray-base;
    font-weight: 300;
    font-size: r(12);
    margin-bottom: 12px;
  }
  .row {
    > article {
      margin-bottom: 16px;
    }
  }
  &__item {
    display: block;
    overflow: hidden;
    > img {
      transform: scale(1, 1);
      transition: transform 1s linear;
    }
    > div {
      color: $color-white;
      background-color: rgba(0,0,0,0.55);
      text-decoration: none;
      text-align: center;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      display: flex;
      width: 100%;
      height: 100%;
      padding: 16px;
      align-items: center;
      justify-content: center;
      transition: background-color .3s ease;
    }
    &:hover {
      > img {
        transform: scale(1.07, 1.07);
      }
      > div {
        background-color: rgba(21,175,151,0.65)!important;
      }
      h2 {
        &::before {
          width: 136px;
          margin-left: -68px;
        }
      }
    }
    &:focus {
      outline: 0;
    }
    h2 {
      font-weight: 700;
      font-size: r(19);
      line-height: 1.2;
      text-transform: uppercase;
      position: relative;
      padding-bottom: 15px;
      margin-bottom: 10px;
      &::before {
        content: "";
        background-color: $color-white;
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 68px;
        height: 2px;
        margin-left: -34px;
        transition: all .7s ease;
      }
    }
    p {
      font-weight: 300;
      max-width: 420px;
      margin: 0 auto;
    }
    &--large {
      > img {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        max-height: none;
        width: 100%;
      }
      &::after {
        padding-bottom: 340/600*100%;
      }
    }
    &--small {
      > img {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        max-height: none;
        width: 100%;
      }
      &::after {
        padding-bottom: 342/342*100%;
      }
    }
    &--touch-screens {
      &:hover {
        > div {
          background-color: rgba(0,144,215,0.65)!important;
        }
      }
    }
    &--sales-support-tool {
      &:hover {
        > div {
          background-color: rgba(162,198,23,0.65)!important;
        }
      }
    }
    &--video-wall {
      &:hover {
        > div {
          background-color: rgba(234,81,103,0.65)!important;
        }
      }
    }
    &--prototypes-ideas,
	&--digital-showroom-journey {
      &:hover {
        > div {
          background-color: rgba(229,209,162,0.65)!important;
        }
      }
    }
	&--exterior-led-screns {
      &:hover {
        > div {
          background-color: rgba(255,243,116,0.65)!important;
        }
      }
    }
    &-r {
      position: relative;
      float: left;
      width: 238px;
      img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
      .home__item {
        position: relative;
        &::after {
          content: "";
          display: block;
          padding-bottom: (160/238*100%);
        }
      }
      .home__item--third {
        &:after {
          padding-bottom: 600/340*100%;
        }
      }
    }
  }
  &-i {
    position: absolute;
    right: 20px;
    bottom: 20px;
    z-index: 2;
    > span {
      text-indent: -9999px;
      vertical-align: middle;
      display: inline-block;
      margin-left: 5px;
    }
    &__news {
      @include sprite("new");
    }
    &__images {
      @include sprite("photo");
    }
    &__doc {
      @include sprite("doc");
    }
    &__video {
      @include sprite("play");
    }
    &--large {
      z-index: 10;
      @include center("both");
      > span {
        text-indent: -9999px;
        display: block;
      }
      &__news {
        @include sprite("new-large");
      }
      &__images {
        @include sprite("photo-large");
      }
      &__doc {
        @include sprite("doc-large");
      }
      &__video {
        @include sprite("play-large");
      }
    }
  }
  &__label {
    font-weight: 700;
    font-size: r(14);
    line-height: 37px;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    position: absolute;
    top: 13px;
    right: 0;
    z-index: 2;
    width: 75px;
    height: 40px;
    padding-top: 2px;
    &--new {
      color: $color-black;
      background-color: $color-yellow-base;
      border-bottom: 1px solid #b9a231;
      &::before {
        border-color: $color-yellow-base transparent transparent transparent;
      }
    }
    &--latest {
		width: 130px;
    }
    &--update {
      color: $color-white;
      background-color: #5EACE5;
      border-bottom: 1px solid #4d8cbb;
      &::before {
        border-color: #5EACE5 transparent transparent transparent;
      }
    }
    &::before {
      content: "";
      position: absolute;
      top: 40px;
      right: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 8px 8px 0 0;
    }
  }
  .home__item-r {
    .home__label {
      font-size: r(10);
      line-height: 27px;
      top: 10px;
      right: -6px;
      width: 50px;
      height: 28px;
      &::before {
        top: 27px;
        border-width: 6px 6px 0 0;
      }

      &--latest {
        width: 105px;
      }
    }
    .home__item > div {
      background-color: rgba(0,0,0,0.35);
    }
  }
  &__button {
    text-align: center;
    padding-top: 30px;
  }
  &__list {
    > li {
      margin-bottom: 8px;
    }
    > li:nth-child(even) {
      .home__content-r {
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f0efef+0,f0efef+47,f3f2f2+56,e4e4e4+56,f3f2f2+56,f4f3f3+57,fefefe+81,ffffff+100&1+0,0+100 */
        background: -moz-linear-gradient(left, rgba(240,239,239,1) 0%, rgba(240,239,239,0.53) 47%, rgba(243,242,242,0.44) 56%, rgba(244,243,243,0.43) 57%, rgba(254,254,254,0.19) 81%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left, rgba(240,239,239,1) 0%,rgba(240,239,239,0.53) 47%,rgba(243,242,242,0.44) 56%,rgba(244,243,243,0.43) 57%,rgba(254,254,254,0.19) 81%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, rgba(240,239,239,1) 0%,rgba(240,239,239,0.53) 47%,rgba(243,242,242,0.44) 56%,rgba(244,243,243,0.43) 57%,rgba(254,254,254,0.19) 81%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      }
    }
  }
  &__content-r {
    position: relative;
    min-height: 160px;
    padding: 10px 516px 10px 45px;
    overflow: hidden;
    h2 {
      font-weight: 700;
      font-size: r(16);
      text-transform: uppercase;
      margin-bottom: 15px;
      > a {
        color: $color-black;
        text-decoration: none;
        transition: color .3s ease;
        &:hover {
          color: $color-green-base;
        }
      }
    }
    p {
      font-weight: 300;
      font-size: r(14);
      margin-bottom: 0;
    }
  }
  &__more {
    color: $color-black;
    font-weight: 300;
    font-size: r(12);
    text-decoration: underline;
    position: absolute;
    bottom: 10px;
    left: 45px;
    transition: color .3s ease;
    &:hover {
      color: $color-green-base;
    }
  }
  &__actions {
    position: absolute;
    top: 50%;
    right: 0;
    width: 500px;
    height: 140px;
    padding-top: 40px;
    padding-left: 55px;
    border-left: 1px solid #e4e4e4;
    margin-top: -70px;
    ul {
      > li {
        vertical-align: top;
        display: inline-block;
        margin-right: 30px;
        > a {
          color: $color-black;
          font-weight: 700;
          font-size: r(8);
          text-transform: uppercase;
          position: relative;
          display: block;
          padding-top: 40px;
          &.download {
            &::before {
              content: "";
              position: absolute;
              top: 0;
              left: 50%;
              margin-left: -11.5px;
              @include sprite("arrow-bottom-black");
            }
          }
          &.fullsize {
            &::before {
              content: "";
              position: absolute;
              top: 0;
              left: 50%;
              margin-left: -15px;
              @include sprite("zoom");
            }
		  }
        }
      }
    }
  }
}
.filters {
  padding: 13px 0 10px;
  padding: 13px 0 13px;
  > li {
    display: inline-block;
    margin-right: 40px;
    > a {
      color: $color-black;
      font-weight: 300;
      font-size: r(12);
      text-decoration: none;
      transition: color .3s ease;
      > span {
        font-size: r(9);
      }
      &:hover {
        color: $color-green-base;
      }
    }
    &.is-active {
      > a {
        color: $color-green-base;
      }
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
.modal {
  -webkit-overflow-scrolling: touch;

  &--large {
    .modal-dialog {
      max-width: 1050px;
    }
    .modal-content {
      border: 0;
      border-radius: 0;
    }
    .modal-body {
      padding: 0;
      .row {
        margin-right: 0;
        margin-left: 0;
		position: relative;
		overflow: hidden;

        > div {
          position: relative;
          padding-right: 0;
          padding-left: 0;
          &.modal--large__content {
            padding: 16px 16px 120px;
          }
        }
      }
    }
    .close {
      text-indent: -9999px;
      position: absolute;
      top: 0;
      right: -50px;
      display: block;
      cursor: pointer;
      transform: rotate(0deg);
      transition: transform .5s ease;
      @include sprite("close");
      &:hover {
        transform: rotate(180deg);
      }
    }
    &__content {
      font-weight: 300;

      h2 {
        color: $color-gray-darken;
        font-weight: 900;
        font-size: r(20);
        line-height: 1.2;
        text-transform: uppercase;
        margin-bottom: 20px;
        > span {
          text-transform: none;
          display: block;
        }
      }
      p {
        color: $color-gray-lighten;
        font-size: r(14);
        padding-bottom: 15px;
        margin-bottom: 0;
      }
    }
    .download-box {
		margin-top: 15px;
		table {
			color: $color-gray-lighten;
			font-size: r(14);
			width: 100%;

			td {
        width: 35%;
        &:first-child {
          padding-right: 5px;
        }
			}

			td:last-child {
			  font-weight: 700;
			  width: 65%;
			  padding-right: 16px;
			}

		}
	}
    .modal--bottom {
      color: $color-gray-base;
      position: absolute;
      right: 16px;
      bottom: 26px;
      left: 16px;
      padding-right: 90px;
      p {
        font-size: r(10);
        line-height: 1.2;
        padding-bottom: 0;
        margin-top: 5px;
      }
      > span {
        font-size: r(10);
      }
    }
    .tags {
      font-size: r(12);
      display: block;
      margin-bottom: 3px;
    }
    .tag {
      color: $color-white;
      background-color: $color-modal-gray-base;
      font-size: r(10);
      text-decoration: none;
      display: inline-block;
      padding: 1px 4px;
      border-radius: 3px;
      margin-left: 3px;
      transition: background-color .3s ease;
      &:hover {
        background-color: darken($color-modal-gray-base, 5%);
      }
    }
    .modal-i {
      position: absolute;
      right: 0;
      bottom: 0;
      &__images {
        text-indent: -9999px;
        display: inline-block;
        margin-left: 5px;
        @include sprite("photo-gray");
      }
      &__pdf {
        text-indent: -9999px;
        display: inline-block;
        margin-left: 5px;
        @include sprite("pdf");
      }
      &__indd {
        text-indent: -9999px;
        display: inline-block;
        margin-left: 5px;
        @include sprite("indd");
      }
      &__docx, &__doc {
        text-indent: -9999px;
        display: inline-block;
        margin-left: 5px;
        @include sprite("word");
      }
      &__video {
        text-indent: -9999px;
        display: inline-block;
        margin-left: 5px;
        @include sprite("play-gray");
      }
	  &__news {
        text-indent: -9999px;
        display: inline-block;
        margin-left: 5px;
	    @include sprite("new");
	  }

    }
  }
  &--slideshow {
    color: $color-white;
    background-color: $color-black;
    padding-bottom: 80px;
    pointer-events: auto;
    .img-holder,
    .video-holder {
      padding: 0 4px;
    }
    .img-holder .img-holder {
      padding: 0;
    }
    .video {
      position: relative;
      > video {
        display: block;
      }
    }
    .home__label {
      font-weight: 700;
      font-size: r(10);
      line-height: 29px;
      text-transform: uppercase;
      text-align: center;
      position: absolute;
      top: 5px;
      right: 0;
      z-index: 2;
      width: 55px;
      height: 30px;
      &::before {
        top: 30px;
        border-width: 5px 5px 0 0;
      }
    }
    .home__item {
      width: 250px;
      margin-right: 5px;
      margin-left: 5px;
      h2 {
        font-size: r(14);
        &::before {
          width: 50px;
          margin-left: -25px;
        }
      }
      p {
        font-size: r(12);
      }
      &:hover {
        h2 {
          &::before {
            width: 100px;
            margin-left: -50px;
          }
        }
      }
    }
    h3 {
      font-weight: 900;
      font-size: r(18);
      line-height: 1.2;
      text-transform: uppercase;
      padding: 30px;
      margin-bottom: 0;
    }
    .slick-prev {
      background-color: $color-black;
      text-indent: -9999px;
      position: absolute;
      top: 50%;
      left: 0;
      z-index: 1;
      display: block;
      width: 44px;
      height: 80px;
      border: 0;
      outline: none;
      margin-top: -40px;
      cursor: pointer;
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -9px;
        margin-left: -4.5px;
        @include sprite("arrow-left-white");
      }
    }
    .slick-next {
      background-color: $color-black;
      text-indent: -9999px;
      position: absolute;
      top: 50%;
      right: 0;
      z-index: 1;
      display: block;
      width: 44px;
      height: 80px;
      border: 0;
      outline: none;
      margin-top: -40px;
      cursor: pointer;
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -9px;
        margin-left: -4.5px;
        @include sprite("arrow-right-white");
      }
    }
  }
}
@media (max-width: 1150px) {
  .modal--large {
    .close {
      top: 10px;
      right: 10px;
      z-index: 3;
    }
    h2 {
      padding-right: 32px;
    }
    .breadcrumb {
      padding: 0 16px;
    }
  }
}
.modal-backdrop {
  background-color: transparent;
  background-color: #000;
  transition: opacity 0.35s linear;
  &:before {
    content: "";
    position: absolute;
    top: -100%;
    left: -100%;
    width: 300%;
    height: 300%;
    // background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 900'%3E%3Cpath fill='%23A0E2DB' d='M0 504 0 899 1600 899 1600 120z'/%3E%3Cpath fill='%23CBF2F3' d='M1600 567 1600 899 0 899 0 165z'/%3E%3Cpath fill='%23719D71' d='M0 680 0 899 1600 899 1600 300z'/%3E%3Cpath fill='%237EB67E' d='M1600 719 1600 899 0 899 0 353z'/%3E%3Cpath fill='%23004D66' d='M0 788 0 899 1600 899 1600 625z'/%3E%3C/svg%3E");
    background-image: url("images/bg/preloader.svg");
    background-size: cover;
    background-position: center;
    animation: rotate-center 2s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
    opacity: 0;
    transition: opacity 0.35s linear;
  }
  &.show {
    opacity: 0.9;
  }
  &.loading {
    background-color: transparent;
    transition: none;
    &:before {
      opacity: 1;
      transition: opacity 0.8s linear;
    }
    &.show {
      opacity: 1;
      &:before {
        opacity: 1;
      }
    }
  }
  // &.complete {
  //   background-color: #000;
  //   opacity: 0.9;
  //   &:before {
  //     opacity: 0;
  //     transition: opacity 0.35s linear;
  //   }
  // }
}
.breadcrumb {
  color: $color-gray-base;
  font-size: r(12);
  position: relative;
  z-index: 1;
  margin-bottom: 12px;
  pointer-events: auto;
  min-height: 12px * 1.5; // font-size * line-height
  a {
    color: $color-gray-base;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}
.video__overlayer {
  background-size: cover;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  cursor: pointer;
  &::before {
    content: "";
    background: url("#{$root-images}play.png") no-repeat;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 116px;
    height: 116px;
    margin-top: -58px;
    margin-left: -58px;
  }

}
.modal {
  &__header {
    position: relative;
    overflow: hidden;
	.triangle {
		z-index: 1;
	}

    &::after {
      padding-bottom: (337/1050*100%);
    }
    > img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
    &-content {
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+1,000000+1,000000+100&0+0,0.04+10,0.16+26,0.66+76,0.76+90,0.8+100 */
      background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(0,0,0,0) 1%, rgba(0,0,0,0.04) 10%, rgba(0,0,0,0.16) 26%, rgba(0,0,0,0.66) 76%, rgba(0,0,0,0.76) 90%, rgba(0,0,0,0.8) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(0,0,0,0) 1%,rgba(0,0,0,0.04) 10%,rgba(0,0,0,0.16) 26%,rgba(0,0,0,0.66) 76%,rgba(0,0,0,0.76) 90%,rgba(0,0,0,0.8) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(0,0,0,0) 1%,rgba(0,0,0,0.04) 10%,rgba(0,0,0,0.16) 26%,rgba(0,0,0,0.66) 76%,rgba(0,0,0,0.76) 90%,rgba(0,0,0,0.8) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      align-items: center;
      justify-content: center;
      display: flex;
      height: 150px;
      h2 {
        color: $color-white;
        font-weight: 900;
        font-size: r(45);
        line-height: 1.2;
        align-self: flex-end;
        width: 100%;
        padding: 0 60px 30px 30px;
        margin-bottom: 0;
      }
      .home-i {
        right: 30px;
        bottom: 30px;
      }
    }
  }
  &__info {
    color: $color-gray-base;
    position: relative;
    padding: 15px 30px;
	min-height: 48px;
    > div.tags {
      margin-bottom: 0;
    }
    > span {
      font-size: r(14);
      line-height: 16px;
      display: block;
      padding-left: 25px;
      @include center("both");
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        margin-top: -8.5px;
        background-image: url("./images/content/Ikona_hodiny.svg");
        background-size: cover;
        width: 17px;
        height: 17px;
      }
    }
    > div.added {
      font-size: r(10);
      line-height: 16px;
      position: absolute;
      top: 15px;
      right: 30px;
    }
  }
  &__intro {
    max-width: 806px;
	width: 100%;
    padding: 40px 8px;
    margin: 0 auto;
    > p {
      color: $color-gray-darken;
      font-weight: 700;
      text-align: center;
      padding-bottom: 40px;
      border-bottom: 2px solid #e1e1e1;
      margin-bottom: 40px;
    }
/*    .entry-content {
      margin-bottom: 20px;
    }*/
  }
  &__anchors{
    padding-bottom: .5rem !important;
    padding-left: .5rem !important;
    padding-right: .5rem !important;
    li{
      display:flex;
      align-items:center;
      flex-wrap: wrap;
      flex-flow: column;
      a{
          align-items: center;
          display: flex !important;
          color: #000;
          font-weight: bold;
          padding-bottom: 1rem !important;
          text-transform: uppercase;
          text-align:center;
          &:after{
            content: "";
            min-width: 6px;
            min-height: 9px;
            width: 6px;
            height:10px;
            background-image: url("images/content/arrow-right-green-strong.png");
            position: relative;
            background-size: cover;
            margin-left: 10px;
            background-repeat: no-repeat;
          }
      
      }
    }
  }

  &__desc {
    color: $color-gray-lighten;
    font-weight: 300;
    font-size: r(14);
    line-height: 1.2;
    max-width: 806px;
    padding: 20px 8px;
    margin: 0 auto 25px;
  }
  &__title-1 {
    color: $color-gray-darken;
    font-weight: 700;
    font-size: r(25);
    line-height: 1.2;
    text-align: center;
    padding: 0 8px;
    margin-bottom: 90px;
  }
  &__list {
    max-width: 996px;
    margin: 0 auto!important;
    > div {
      position: relative;
      padding-right: 30px!important;
      padding-bottom: 20px;
      padding-left: 30px!important;
      margin-bottom: 45px;
      &:first-child {
        &::before {
          display: none;
        }
      }
      &::before {
        content: "";
        background-color: #e3e3e3;
        position: absolute;
        top: 0;
        bottom: 0;
        left: -1px;
        width: 2px;
        height: 100%;
      }
    }
    img {
      margin-right: auto;
      margin-bottom: 20px;
      margin-left: auto;
    }
    ul {
      font-size: r(14);
    }
  }
  &__button {
    text-align: center;
    padding-top: 45px;
    margin-bottom: 90px;
    > a {
      padding: 14px 50px 14px 30px;
      &::before {
        right: 30px;
      }
    }
  }
  &__at-right {
    max-width: 470px;
    padding-right: 60px!important;
    padding-left: 60px!important;
  }
}
#articleDetail {
  &.fade {
    transition: opacity 0.35s linear;

    .modal-dialog {
      transform: translate(0, 0);
    }
  }
  &.blocked {
    opacity: 0;
  }
  
  .modal-content {
	  display: block;
  }
}
.triangle {
  position: absolute;
  top: -30px;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 190px 110px 50px 0;
}
.tabs {
  background-color: #f5f5f5;
  padding-top: 90px;
  margin-top: 90px;
  margin-bottom: 40px;
  &--without-header {
    padding-top: 0;
    margin-top: 0;
  }
  &__header {
    text-align: center;
    padding: 0 16px;
    > h3 {
      color: $color-gray-darken;
      font-weight: 700;
      font-size: r(30);
      line-height: 1.2;
      text-transform: uppercase;
      margin-bottom: 30px;
    }
    > p {
      color: $color-gray-lighten;
      font-weight: 300;
      max-width: 760px;
      margin: 0 auto 70px;
    }
  }
  &__content {
    .nav {
      background-color: #f5f5f5;
    }
    > div {
      > ul {
        display: table;
        table-layout: fixed;
        width: 100%;
        height: 55px;
        border-bottom: 0;
        > li {
          background-color: #f5f5f5;
          vertical-align: middle;
          display: table-cell;
          // width: 20%;
          height: 55px;
          padding: 0;
          > a {
            color: $color-gray-lighten;
            font-size: r(13);
            text-transform: uppercase;
            text-align: center;
            position: relative;
            vertical-align: middle;
            display: table;
            width: 100%;
            height: 55px;
            padding: 0 5px;
            > span {
              vertical-align: middle;
              display: table-cell;
              height: 55px;
            }
            &::before {
              content: "";
              background-color: #e7e7e7;
              position: absolute;
              top: 0;
              left: 0;
              width: 1px;
              height: 55px;
            }
            &.active {
              background-color: #7ec9f1;
			  color: #FFF;
            }
            &:hover {
              background-color: darken(#7ec9f1, 3%);
			  color: #FFF;
              text-decoration: none;
            }
          }
          &:first-child {
            > a {
              &::before {
                display: none;
              }
            }
          }
        }
      }
    }
    .tab-content {
      background-color: #e8e8e8;
    }
  }
  .content {
    color: $color-gray-lighten;
    font-weight: 300;
    padding: 16px 60px!important;
    p {
      margin-bottom: 0;
    }
  }
}
.slideshow {
  margin-bottom: 90px;
  &__header {
    color: $color-gray-darken;
    text-align: center;
    padding: 0 16px;
    > h3 {
      font-weight: 700;
      font-size: r(30);
      line-height: 1.2;
      text-transform: uppercase;
      margin-bottom: 30px;
    }
    > p {
      font-weight: 300;
      max-width: 760px;
      margin: 0 auto 70px;
    }
  }
}
.slider {
  .content {
    color: $color-gray-lighten;
    padding: 30px 100px 30px 60px;
    p {
      font-weight: 300;
      margin-bottom: 0;
    }
  }
  &__title {
    font-weight: 700;
    font-size: r(25);
    margin-bottom: 20px;
  }
  .slick-prev {
    background-color: $color-black;
    text-indent: -9999px;
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 1;
    display: block;
    width: 44px;
    height: 80px;
    border: 0;
    outline: none;
    margin-top: -40px;
    cursor: pointer;
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -9px;
      margin-left: -4.5px;
      @include sprite("arrow-left-white");
    }
  }
  .slick-next {
    background-color: $color-black;
    text-indent: -9999px;
    position: absolute;
    top: 50%;
    right: 0;
    z-index: 1;
    display: block;
    width: 44px;
    height: 80px;
    border: 0;
    outline: none;
    margin-top: -40px;
    cursor: pointer;
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -9px;
      margin-left: -4.5px;
      @include sprite("arrow-right-white");
    }
  }
  .slick-dots {
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: 57px;
    > li {
      display: inline-block;
      padding: 0 3px;
      > button {
        background-color: #797a78;
        text-indent: -9999px;
        display: block;
        width: 8px;
        height: 8px;
        padding: 0;
        border: 0;
        border-radius: 50%;
        outline: none;
        box-shadow: none;
        transition: background-color .3s ease;
      }
      &.slick-active {
        > button {
          background-color: $color-black;
        }
      }
    }
  }
}
.f-slideshow {
  &__header {
    background-color: #f5f5f5;
    text-align: center;
    padding: 90px 16px;
    > h3 {
      color: $color-gray-darken;
      font-weight: 700;
      font-size: r(30);
      line-height: 1.2;
      margin-bottom: 30px;
    }
    > p {
      color: $color-gray-lighten;
      font-weight: 300;
      max-width: 760px;
      margin: 0 auto;
    }
  }
}
.video-box {
  background-color: #f5f5f5;
/*  padding-bottom: 65px;*/
  margin-bottom: 75px;
  &__desc {
    color: $color-gray-lighten;
    font-weight: 300;
    font-size: r(14);
    line-height: 1.2;
    max-width: 806px;
    padding: 20px 8px;
    margin: 0 auto 65px;
  }
}
.timeline {
  padding-bottom: 70px;
  &__header {
    text-align: center;
    padding: 16px;
    margin-bottom: 50px;
    > h3 {
      color: $color-gray-darken;
      font-weight: 700;
      font-size: r(30);
      line-height: 1.2;
      text-transform: uppercase;
      margin-bottom: 30px;
    }
    > p {
      color: $color-gray-lighten;
      font-weight: 300;
      max-width: 760px;
      margin: 0 auto;
    }
  }
}
.common-box {
  margin-bottom: 40px;
  &__desc {
    color: $color-gray-lighten;
    font-weight: 300;
    font-size: r(14);
    line-height: 1.2;
    max-width: 806px;
    padding: 0 8px;
    margin: 0 auto 40px;
  }
}
.hotspots-box {
  &__container {
    text-align: center;
  }
  &__image {
    display: inline-block;
    position: relative;
  }
  &__dots {
    background: transparent;
    height: auto;
    display: inline;

    li {
      display: block;
      width: 20px;
      height: 20px;
      position: absolute;
    }
  }
  &__dot {
    position: relative;
    display: block;
    width: 20px;
    height: 20px;
    background: red;
    border-radius: 100%;
    transform: translate(-50%,-50%);
  }
}

.modal-bottom {
  text-align: center;
  padding: 70px 0;
  pointer-events: auto;
  background: $color-black;
  .download,
  .print {
    position: relative;
    > strong {
      position: absolute;
      top: 100%;
      left: 50%;
      padding-top: 10px;
      color: #fff;
      font-size: r(10);
      font-weight: 700;
      text-transform: uppercase;
      transform: translateX(-50%) scale(1, 0);
      transition: transform 0.15s;
      white-space: nowrap;
    }
    &:hover {
      > strong {
        transform: translateX(-50%) scale(1, 1);
      }
    }
  }
  .download {
    @include sprite("download-large");
    display: inline-block;
    margin: 0 20px;
    background-image: none;
    span {
      @include sprite("download-large");
      position: absolute;
      top: 0;
      left: 0;
      display: inline-block;
      background-clip: content-box;
    }
    .download-inner {
      background-image: none;
      overflow: hidden;
    }
    .download-first {
      padding-bottom: 3px;
      opacity: 0;
      transform: translateY(-100%);
      transition: none;
      visibility: hidden;
    }
    .download-second {
      padding-bottom: 3px;
      opacity: 1;
      transition: none;
    }
    .download-bottom {
      padding-top: 31px - 3px;
    }
    &:hover {
      .download-first {
        opacity: 1;
        transform: translateY(0);
        transition: opacity 0.05s 0.05s, transform 0.25s 0.05s, visibility 0.05s 0.05s;
        visibility: visible;
      }
      .download-second {
        opacity: 0;
        transition: opacity 0.05s;
      }
    }
  }
  .print {
    @include sprite("printer");
    display: inline-block;
    margin: 0 20px;
    background-image: none;
    span {
      position: absolute;
      top: 0;
      left: 0;
      display: inline-block;
    }
    .print-machine {
      @include sprite("printer-machine");
    }
    .print-inner {
      @include sprite("printer-machine");
      top: 14px;
      background-image: none;
      overflow: hidden;
    }
    .print-paper-first,
    .print-paper-second {
      @include sprite("printer-paper");
      top: -14px;
    }
    .print-paper-first {
      opacity: 0;
      transform: translateY(-100%);
      transition: none;
      visibility: hidden;
    }
    .print-paper-second {
      opacity: 1;
      transition: none;
    }
    &:hover {
      .print-paper-first {
        opacity: 1;
        transform: translateY(0);
        transition: opacity 0.05s 0.05s, transform 0.25s 0.05s, visibility 0.05s 0.05s;
        visibility: visible;
      }
      .print-paper-second {
        opacity: 0;
        transition: opacity 0.05s;
      }
    }
  }
}
.modal-btm {
  text-align: center;
  pointer-events: auto;
  padding: 40px 0;
  background: $color-black;
  > a {
    color: $color-green-base;
    font-weight: 700;
    font-size: r(12);
    text-transform: uppercase;
    position: relative;
    display: inline-block;
    padding-top: 30px;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      margin-left: -15.5px;
      @include sprite("back");
    }
  }
}
.gallery {
	&__container {
		background: #f5f5f5;
		margin-bottom: 40px;
		padding: 40px 0;

		.slick-arrow {
			display: none!important;
		}
		.slick-dots {
			display: flex;
			height: 5px;
			background: #ebebeb;
			width: 100%;
			margin-top: 30px;

			li {
				flex: 1;
				background: transparent;
				border-radius: 3px;
				height: 7px;
				margin: -1px 0 0 0;
				position: relative;
				z-index: 1;

				button {
					width: 100%;
					height: 100%;
					position: absolute;
					overflow: hidden;
					text-indent: -999px;
					border: 0;
					margin: 0;
					padding: 0;
					cursor: pointer;
					background: transparent;
					outline: none!important;
				}

				&.slick-active {
					background: #707070;
				}
			}
		}

		.slick-slide {

			li {
				span {
					position: relative;
					display: block;
          overflow:hidden;
          background-size:cover;
          background-position:center center;

					&:before {
						position: absolute;
						top: 0;
						left: 0;
						right: 0;
						height: 100%;
						content: "";
						background: rgba(0,0,0, 0.55);
						display: block;
						transition: opacity linear 0.5s;
						cursor: pointer;
					}
          img{
            width: auto;
            max-width:none;
            left:50%;
            height:100%;
            position:absolute;
            top:50%;
            transform: translate(-50%,-50%);

          }
				}
			}

			&.slick-current li span {
				&:before {
					opacity: 0;
				}
			}
		}
	}
	&__header {
		background-color: #f5f5f5;
		text-align: center;
		padding: 90px 120px;

		@media (max-width: map-get($grid-breakpoints, md) - 1) {
			padding: 30px 15px;
		}

		> h3 {
		  color: $color-gray-darken;
		  font-weight: 700;
		  font-size: r(30);
		  line-height: 1.2;
		  margin-bottom: 30px;
		}

		> p {
		  color: $color-gray-lighten;
		  font-weight: 300;
		  max-width: 760px;
		  margin: 0 auto;
		}
	}
	&_footer {
		padding: 0 120px 90px 120px;

		@media (max-width: map-get($grid-breakpoints, md) - 1) {
			padding: 0 15px 30px 15px;
		}
	}
	&__main-slider {
		li {
			padding: 0 4px;
			height: 540px;

			span {
				height: 540px;
				width: 100%;
			}

			img {
				height: 540px;
				display: block;
				margin: 0 auto;
			}

			@media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, xl) - 1) {
				height: 320px;

				span {
					height: 320px;
				}
				img {
					height: 320px;
				}
			}

			@media (max-width: map-get($grid-breakpoints, md) - 1) {
				height: 200px;

				span {
					height: 200px;
				}
				img {
					height: 200px;
				}
			}
		}
		.slick-slide {
			cursor: pointer;

			&.slick-current {
				cursor: default;
			}
		}
	}
	&__thumb-slider {
		li {
			padding: 0 4px;
			height: 128px;

			span {
				height: 128px;
				width: 100%;
        overflow:hidden;
        background-size:cover;
        background-position:center center;
			}

			img {
				cursor: pointer;
				max-height: 128px;
        height:100%;
				display: block;
				margin: 0 auto;
        max-width:none;
        width:auto;
        position:absolute;
        left:50%;
        top:50%;
        transform: translate(-50%,-50%);
      

			}

			@media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, xl) - 1) {
				height: 85px;

				span {
					height: 85px;
				}
				img {
					max-height: 85px;
				}
			}

			@media (max-width: map-get($grid-breakpoints, md) - 1) {
				height: 85px;

				span {
					height: 85px;
					width: 100%;
				}

				img {
					cursor: pointer;
					max-height: 85px;
					display: block;
					margin: 0 auto;
				}
			}
		}
	}
	&__thumb-slider-container {
		padding: 30px 124px 0 124px;
		margin-bottom: -10px;

		@media (max-width: map-get($grid-breakpoints, md) - 1) {
			padding: 30px 15px 30px 15px;
		}
	}
}
.switches {
  float: left;
  width: 80px;
  padding-top: 17px;
}
.switch {
  float: left;
  > a {
    text-indent: -9999px;
    display: block;
    width: 16px;
    height: 16px;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 16px 16px;
  }
  &--cols {
    margin-right: 12px;
    > a {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTYgMTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTAgMGg0djRIMFYwem02IDBoNHY0SDZWMHptNiAwaDR2NGgtNFYwek0wIDZoNHY0SDBWNnptNiAwaDR2NEg2VjZ6bTYgMGg0djRoLTRWNnpNMCAxMmg0djRIMHYtNHptNiAwaDR2NEg2di00em02IDBoNHY0aC00di00eiIgZmlsbD0iIzlEOUQ5QyIgZmlsbC1ydWxlPSJub256ZXJvIi8+PC9zdmc+');
      &:hover {
        background-image: url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTYgMTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTAgMGg0djRIMFYwem02IDBoNHY0SDZWMHptNiAwaDR2NGgtNFYwek0wIDZoNHY0SDBWNnptNiAwaDR2NEg2VjZ6bTYgMGg0djRoLTRWNnpNMCAxMmg0djRIMHYtNHptNiAwaDR2NEg2di00em02IDBoNHY0aC00di00eiIgZmlsbD0iIzQxQTYyQSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PC9zdmc+');
      }
    }
    &.is-active {
      > a {
        background-image: url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTYgMTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTAgMGg0djRIMFYwem02IDBoNHY0SDZWMHptNiAwaDR2NGgtNFYwek0wIDZoNHY0SDBWNnptNiAwaDR2NEg2VjZ6bTYgMGg0djRoLTRWNnpNMCAxMmg0djRIMHYtNHptNiAwaDR2NEg2di00em02IDBoNHY0aC00di00eiIgZmlsbD0iIzQxQTYyQSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PC9zdmc+');
      }
    }
  }
  &--rows {
    > a {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTYgMTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTAgMGgxNnY0SDBWMHptMCA2aDE2djRIMFY2em0wIDZoMTZ2NEgwdi00eiIgZmlsbD0iIzlEOUQ5QyIgZmlsbC1ydWxlPSJub256ZXJvIi8+PC9zdmc+');
      &:hover {
        background-image: url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTYgMTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTAgMGgxNnY0SDBWMHptMCA2aDE2djRIMFY2em0wIDZoMTZ2NEgwdi00eiIgZmlsbD0iIzQxQTUyQSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PC9zdmc+');
      }
    }
    &.is-active {
      > a {
        background-image: url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTYgMTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTAgMGgxNnY0SDBWMHptMCA2aDE2djRIMFY2em0wIDZoMTZ2NEgwdi00eiIgZmlsbD0iIzQxQTUyQSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PC9zdmc+');
      }
    }
  }
}


.color {
	&--digital-showroom {
		.nav &
		{
			&:hover {
			  > a,
			  > span {
				border-bottom-color: #15af97;
			  }
			}
		}
	  }
	&--sales-support-tool {
		.nav &
		{
			&:hover {
			  > a,
			  > span {
				border-bottom-color: #a2c617;
			  }
			}
		}
	}
	&--video-wall {
		.nav &
		{
			&:hover {
			  > a,
			  > span {
				border-bottom-color: #ea5167;
			  }
			}
		}
	}
	&--interactive-sales-tools,
	&--touch-screens {
		.nav &
		{
			&:hover {
			  > a,
			  > span {
				border-bottom-color: #0090d7;
			  }
			}
		}
	}
	&--prototypes-ideas,
	&--digital-showroom-journey {
		.nav &
		{
			&:hover {
			  > a,
			  > span {
				border-bottom-color: #e5d1a2;
			  }
			}
		}
	}
	&--exterior-led-screns {
		.nav &
		{
			&:hover {
			  > a,
			  > span {
				border-bottom-color: #fff374;
			  }
			}
		}
	}	
 }
#for-customers,
#for-dealers {
  max-width: 840px;
  margin: 0 auto;
  
  table {
	  td {
		  img {
			  width: 110px;
		  }
	  }
  }
}
.table-row {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.table-col {
  display: table-cell;
  vertical-align: middle;
}
.table-row-rev {
  direction: rtl;

  .table-col {
    direction: ltr;
  }
}

// WELLCOME BOARD - CROSS LINKS
.cross-links {
  .btn-base{
    padding: 15px 50px !important;
  }
  min-height: calc( 100vh - 131px );
  article {
    overflow: hidden;
    &:before {
      content: "";
      background-position: center center;
      background-size: cover;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      -webkit-transform: scale(1, 1);
      transform: scale(1, 1);
      -webkit-transition: -webkit-transform 1s linear;
      transition: -webkit-transform 1s linear;
      transition: transform 1s linear;
      transition: transform 1s linear, -webkit-transform 1s linear;
    }
    &:hover{

      .overlay-layer{
        background-color: rgba(36, 36, 36, 0.9);
        transition: background-color .3s ease;
      }

    &:before {
      -webkit-transform: scale(1.07, 1.07);
      transform: scale(1.07, 1.07);
    }
    }
    &.showroom-formats {
      &:before {
        background-image: url("./images/content/Showroom_Formats.jpg");
      }
    
      &.loaded{

        h2,p{
           transform: translate(0px,0px) !important;
           opacity: 1 !important;
        }
        a{
          opacity:1 !important;
        }


        &:after{
          height:100%;
        }
      }

      h2{
           transition-delay: 0.6s !important;  
      }
       p{
          transition-delay: 0.8s !important;  
      }
      a{
           transition-delay: 1.3s !important;
      }

    }
    &.emobility-age {
      &:before {
        background-image: url("./images/content/eMobility.jpg");
      }
      &:after{
        position: absolute;
        content: "";
        height: 0px;
        right: 0;
        top: 0;
        width: 1px;
        background: rgba(255,255,255,0.3);
        transition: height 1.2s ease;
        transition-delay: 0.4s;
      }
      &.loaded{

        h2,p{
            transform: translate(0px,0px) !important;
           opacity: 1 !important;
        }
        a{
          opacity:1 !important;
        }


        &:after{
          height:100%;
        }
      }

      h2{
           transition-delay: 0.4s !important;  
      }
       p{
          transition-delay: 0.6s !important;  
      }
      a{
           transition-delay: 1.1s !important;
      }
    }
    &.showroom-experience {

      
      &:before {
        background-image: url("./images/content/Digital_Showroom.jpg");
      }
      &:after{
        position: absolute;
        content: "";
        height: 0px;
        right: 0;
        top: 0;
        width: 1px;
        background: rgba(255,255,255,0.3);
        transition: height 1.2s ease;
      }

      &.loaded{
        h2,p{
          transform: translate(0px,0px) !important;
           opacity: 1 !important;
        }
        a{
          opacity:1 !important;
        }

        &:after{
          height:100%;
        }
      }
      h2{
           transition-delay: 0.2s !important;  
      }

      p{
          transition-delay: 0.4s !important;  
      }
      a{
          transition-delay: 0.9s !important;
      }

    }
    .overlay-layer {
      width: 100%;
      height: 100%;
      background-color: rgba(36, 36, 36, 0.96);
      position: absolute;
      top: 0;
      left: 0;
      transition: background-color .3s ease;
      z-index: 0;
    }
    .layer-out {
      color: #fff;
      text-decoration: none;
      position: relative;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 3;
      width: 100%;
      max-height: 560px;
      -webkit-transition: background-color .3s ease;
      transition: background-color .3s ease;
      overflow: hidden;
      flex-flow: column;
      height: 100%;
      padding: 40px 40px;
      &.disabled{
        color: darken($color-gray-base,5%) !important;
      }
      @media (min-width: map-get($grid-breakpoints, lg)) {
        padding: 80px 40px;
      }
      @media (min-width: map-get($grid-breakpoints, xl)) {
        padding: 80px 80px;
      }
      .content-block {
        max-height: 330px;
        h2 {
          font-size: 22px;
          text-transform: uppercase;
          line-height: 1.2;
           transform: translate(-40px,0px);
           opacity: 0;
           transition: transform .5s ease-in-out, opacity 1s ease-in-out;
          @media (min-width: map-get($grid-breakpoints, lg)) {
            min-height: 126px;
            font-size: 35px;
          }
        }

        p{
           transform: translate(-40px,0px);
           opacity: 0;
           transition: transform .5s, opacity 2s ease-in-out;
        }
        
      }
      a{
          opacity: 0;
          transition: opacity 3s ease;

        }
    }

  }
}


.login-page{
     background-color: rgba(0, 0, 0, 0.86) !important;
      
      .layer-out{
        max-height:none !important;
        height: auto !important;
        h1{
          color:#fff;
          font-size: 60px;
          text-align:left;
          text-transform: uppercase;
          font-weight: 900;
          line-height: 115%;

          &>span{
            transform: translate(0px,25px);
            opacity:0;
             transition: opacity 1s, transform 1s;

            &+span{
               transform: translate(0px,25px);
                 opacity:0;
                  transition: opacity 1s, transform 1s;
                  transition-delay:  0.4s;
               &+span{
                 transform: translate(0px,25px);
                   opacity:0;
                    transition: opacity 1s, transform 1s;
                    transition-delay: 1s;
               }
            }
          }
        }
        a{
        transition-delay: 1.8s !important;
        text-transform: none !important;
        font-size:18px;
        padding: 10px 60px;
        }
    }

    .loaded{
      h1{
        &>span{
            transform: translate(0px,0px);
            opacity:1;
            &+span{
               transform: translate(0px,0px);
                 opacity:1;
               &+span{
                 transform: translate(0px,0px);
                   opacity:1;
               }
            }
          }
      }
       a{
          opacity:1 !important;
        }

    }
}

.hotspot{
  .anch {
    display: inline-block;
    position: absolute;
    transform: translate(-50%,-50%);
    &:before{
      content: "";
      background: rgba(97, 182, 72, 0.4);
      width: 52px;
      height: 52px;
      border-radius: 50%;
      position: absolute;
      top: -7px;
      left: -7px;
      z-index: 1;
      pointer-events: none;
      transition: all 1s;
    }
    &:after{
      content: "";
      background: rgba(97, 182, 72, 0.23);
      width: 67px;
      height: 67px;
      border-radius: 50%;
      position: absolute;
      top: -14px;
      left: -14px;
      z-index: 1;
      pointer-events: none;
      transition: all 1s;
    }

    .anch-in{
        background-color: $color-green-base;
        width: 38px;
        height: 38px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 36px;
        font-weight: 100;
        font-style: normal;
        position: relative;
        cursor: pointer;
        z-index: 2;
        transition: all 0.7s;

      i{
        position:relative;
        padding:9px;
        @include sprite("close-white-small");
      }
    }
    &:hover{
       .anch-in{
          background-color: $color-white;
          i{
            @include sprite("close-green-small");
          }
      }
      &:after{
        display: none;
      }
      &:before{
        background-color: rgba(97, 182, 72, 1);
      }
    }
    &.active{
      .anch-in{
        background-color: $color-white;
        
        i{
          @include sprite("close-green-small");
          
        }
      }
    }


    &.anch1 {
      top: 31%;
      left: 22%;
    }
    &.anch2 {
      top: 39%;
      left: 73%;
    }
    &.anch3 {
      top: 67%;
      left: 36%;
    }
    &.anch4 {
      top: 84%;
      left: 19%;
    }
    &.anch5 {
      top: 84%;
      left: 70%;
    }
  }
  .video-box{
    .embed-responsive{
      &:before{
        z-index:5;
      }
    }
    .video-box__desc{
      font-size: 16px;
    }
  }
  .hotspot-content{
    .tab-pane{
      display: block;
      max-height: 0px;
      overflow: hidden;
      position:relative;
      transition: ease-in-out 1s;
       background: #f3f3f3; 

       .toggleSpot{
            @include sprite("close");
            background-repeat: no-repeat;
            background-color: #fff;
            border: 12px solid #fff;
            position: absolute;
            right: 0px;
            top:0px;
            display: flex;
            width: 52px;
            height: 52px;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            transform: none;
            z-index:1;
            &:before{
              display: none;
            }
            &:after{
              display: none;
            }
            .anch-in{
              display:none;
                i{
                  display:none;
                }
              }
            cursor: pointer;
          }
    
            .arrow{
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              height: 75px;
              background-color: #fff;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              padding: 15px 11px;
              border-top: 22px solid #FFF;
              border-left: 7px solid #fff;
              border-bottom: 22px solid #fff;
              z-index: 5;
              &.right{
                right: 0px;
                position: absolute;
              }
              &.left{
              }
              img{
                height: 25px;
              }
            }
          


       .video-box{
        margin-bottom:0px;
        }
       .modal__intro.full__image{
        bottom: 0px;
        padding-bottom: 0px;
        padding-left: 40px; 
        position: absolute;
          .modal__title{
            color:#fff;
            text-align:left;
          }
       }
        
        .tab__content_hot{
          position: relative;
          padding-bottom: 40px;
          margin-bottom: 0px;

          h2{
            text-transform: uppercase;
            font-weight: 300;
          }

        }
        .modal__fullimage,.hotspot_fullpart{
          position: relative;
         
          padding-bottom: 0px;
       
          
          h2{
            position: absolute;
            bottom: 25px;
            left: 20px;
            text-transform: uppercase;
            color: #fff;
          }
         

          img.img-fluid{
            width: 100%;
          }
        }

        .image-hotspot{
            background-size:cover;
            height: 100%;
            min-height:300px;
          }

        @media (max-width: "992px") {
          .tab__content_hot{
            padding-bottom: 40px;
            position: relative;
            &.text__block{
              padding-left:40px;
              padding-right: 40px;
            }
          }
           .modal__intro.full__image{
            .modal__title{
              text-align: center;
              padding-left:20px;
              padding-right:20px;
            }
          }

          .toggleSpot{
            @include sprite("close");
            background-repeat: no-repeat;
            background-color: #fff;
            border: 12px solid #fff;
            position: absolute;
            right: 0px;
            top:0px !important;
            display: flex;
            width: 52px;
            height: 52px;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            transform: none;
            border-radius: 0% !important;
            &:before{
              display: none;
            }
            &:after{
              display: none;
            }
            .anch-in{
              display:none;
                i{
                  display:none;
                }
            }
            cursor: pointer;
          }

        }
        @media (max-width: "600px") {
          .modal__fullimage,.hotspot_fullpart{
            h2{
              left: 0px;
              right: 0px;
              margin: 0 auto;
              text-align: center;
              padding-right: 0px;
            }
            .arrows{
              top: 40%;
            }
          }
        }
      
      &.active{
        max-height: 5000px;
      }
    }
  }
  .arrow{

    position: relative;
    display: flex;
    height: 33px;
    width: 30px;
    cursor: pointer;
    z-index:5;
    &.left{
      @include sprite("left-green");
      left:0px !important;
    }
    &.right{
      @include sprite("right-green");
      right:0px !important;
    }
  }
}

  