.modal__table-1 {
  margin-bottom: 45px;
  table {
    table-layout: fixed;
    width: 100%;
    td {
      font-weight: 400!important;
      text-align: center;
      width: 25%!important;
      padding: 0 32px!important;
      vertical-align: top;
      img {
        max-width: 100px;
        margin: 0 auto;
      }
      p {
        font-size: r(16);
      }
    }
    th,
    td {
      position: relative;
      &::before {
        content: "";
        background-color: #e3e3e3;
        position: absolute;
        top: 0;
        bottom: 0;
        left: -1px;
        width: 2px;
        height: 100%;
      }
      &:first-child {
        &::before {
          display: none;
        }
      }
      .tab-content__7 & {
        padding: 16px 45px !important;
      }
    }
  }
}
.modal__table-2 {
  max-width: 820px + (2 * 45px);
  padding: 40px 0;
  margin: 0 auto;
  text-align: center;
  table {
    width: 100%;
    max-width: 100%;
    table-layout: fixed;
    text-align: left;
    th {
      color: #4a4a4a;
    }
    th,
    td {
      position: relative;
      padding: 6px 45px !important;
      &::before {
        content: "";
        background-color: #e3e3e3;
        position: absolute;
        top: 0;
        bottom: 0;
        left: -1px;
        width: 2px;
        height: 100%;
      }
      &:first-child {
        &::before {
          display: none;
        }
      }
    }
    tr:first-child {
      th,
      td {
        padding-top: 16px;
      }
    }
    tbody tr:last-child {
      th,
      td {
        padding-bottom: 16px;
      }
    }
    thead + tbody {
      tr:first-child {
        th,
        td {
          padding-top: 8px;
        }
      }
    }
  }
  > p {
    margin: 25px 0;
    padding-right: 45px;
    padding-left: 45px;
    text-align: center;
  }
}
.modal__table-3 {
  max-width: 820px + (2 * 45px);
  padding: 80px 0 40px;
  margin: 0 auto;
  text-align: center;
  table {
    width: 100%;
    max-width: 100%;
    table-layout: fixed;
    text-align: left;
    th {
      color: #4a4a4a;
    }
    th,
    td {
      position: relative;
      padding: 6px 45px;
      white-space: nowrap;
      &::before {
        content: "";
        background-color: #e3e3e3;
        position: absolute;
        top: 0;
        bottom: 0;
        left: -1px;
        width: 2px;
        height: 100%;
      }
      &:first-child {
        &::before {
          display: none;
        }
      }
    }
    tr:first-child {
      th,
      td {
        padding-top: 16px;
      }
    }
    tbody tr:last-child {
      th,
      td {
        padding-bottom: 16px;
      }
    }
    thead + tbody {
      tr:first-child {
        th,
        td {
          padding-top: 8px;
        }
      }
    }
  }
  p {
    padding-right: 45px;
    padding-left: 45px;
    color: #4a4a4a;
  }
}
.modal__tabs {
  ul.nav-tabs {
    background-color: $color-white;
    border-bottom: 0;
    display: table;
    width: 100%;
    height: 65px;
    border-bottom: 0;
    overflow: hidden;
    > li {
      background-color: #f5f5f5;
      position: relative;
      vertical-align: middle;
      display: table-cell;
      width: 25%;
      height: 65px;
      padding: 0;
      > a {
        color: $color-gray-lighten;
        font-size: r(13);
        text-transform: uppercase;
        text-align: center;
        vertical-align: middle;
        display: table;
        width: 100%;
        height: 55px;
        padding: 5px;
        > span {
          vertical-align: middle;
          display: table-cell;
          height: 55px;
        }
        &::before {
          content: "";
          background-color: #e7e7e7;
          position: absolute;
          top: 0;
          left: 0;
          width: 1px;
          height: 65px;
        }
        &.active {
          background-color: #7ec9f1;
		  color: #FFF;
        }
        &:hover {
		  background-color: darken(#7ec9f1, 3%);
		  color: #FFF;
          text-decoration: none;
        }
      }
      &:first-child {
        > a {
          &::before {
            display: none;
          }
        }
      }
    }
  }
  .tab__content {
    padding: 16px 60px!important;
  }
}
@media (max-width: map-get($grid-breakpoints, md) - 1) {
  .modal__table-1 {
    overflow: hidden;

    table, tbody, tr, td {
      display: block!important;
    }
    tr td {
      float: left;
      width: 50% !important;
      &::before {
        display: none;
      }

      &:nth-child(odd) {
        clear: left;
      }
    }
  }
  .modal__table-2,
  .modal__table-3 {
    max-width: 100%;
    table {
      table-layout: auto;
    }
  }
  .modal__tabs {
    ul.nav-tabs {
      display: none;
    }
  }
  .modal__tabs .tab__content {
    padding-right: 8px !important;
    padding-left: 8px !important;
  }
}
