// desktop
$container-max-width-lg: map-get($container-max-widths, lg);
$container-max-width-xl: map-get($container-max-widths, xl);
$container-max-width-xxl: map-get($container-max-widths, xxl);

@media (min-width: map-get($grid-breakpoints, lg)) {
  .footer {
	  #map {
		margin-right: calc( ( 100vw - #{$container-max-width-lg} ) / 2 * -1 );
	  }
  }
  .login-page{
    h1{
      font-size: 50px !important;
    }
  }
}

@media (min-width: map-get($grid-breakpoints, xl)) {
  .nav {
    &__container {
      > ul {
        > li:first-child {
          display: none!important;
        }
        > li:last-child {
          display: none!important;
        }
        > li.with-dropdown {
          > ul {
            display: block!important;
          }
        }
      }
    }
  }
  .footer {
	  #map {
		margin-right: calc( ( 100vw - #{$container-max-width-xl} ) / 2 * -1 );
	  }
  }
  .login-page{
    h1{
      font-size: 50px !important;
    }
  }
}

@media (min-width: map-get($grid-breakpoints, xxl)) {
  .footer {
	  #map {
		margin-right: calc( ( 100vw - #{$container-max-width-xxl} ) / 2 * -1 );
	  }
  }

  .login-page{
    h1{
      font-size: 60px !important;
    }
  }
}
