a.btn-base {
  color: $color-white;
  background-color: $color-green-base;
  font-weight: 700;
  font-size: r(12);
  line-height: 1.2;
  text-decoration: none;
  text-transform: uppercase;
  display: inline-block;
  padding: 15px 60px;
  border-radius: 21px;
  transition: background-color .3s ease;
  &:hover {
    background-color: darken($color-green-base, 5%);
  }
  &.disabled{
    color: darken($color-gray-base,5%);
    background: $color-gray-darken;
    &:hover {
      background: $color-gray-darken;
      color: darken($color-gray-base,5%);
      cursor: not-allowed;
    }
  }
}
a.btn-download {
  color: $color-white;
  background-color: $color-green-base;
  font-weight: 700;
  font-size: r(12);
  line-height: 1.2;
  text-decoration: none;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  padding: 10px 40px 10px 20px;
  border-radius: 21px;
  transition: background-color .3s ease;
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    right: 20px;
    margin-top: -8.5px;
    @include sprite("download");
    transform: translateY(0);
    transition: transform .3s ease;
  }
  &:hover {
    background-color: darken($color-green-base, 5%);
    &::before {
      transform: translateY(4px);
    }
  }
}
