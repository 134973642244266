.table-50 {
  width: 100%;
  td {
    text-align: center;
    width: 50%;
    padding: 0 100px;
    p {
      margin-top: 0;
    }
  }
  img {
    max-width: 100px;
    margin: 0 auto;
  }
}
@media (max-width: map-get($grid-breakpoints, md) - 1) {
  .table-50 {
    width: 100%;
    td {
      padding: 0 16px;
    }
  }
}
