.tabs__left {
  padding-left: 130px!important;
  table {
    width: 100%;
    td {
      position: relative;
      font-size: r(14);
      width: 50%;
      padding-left: 45px;
      &.top {
        font-size: r(12);
        text-transform: uppercase;
      }
      &::before {
        content: "";
        background-color: #e3e3e3;
        position: absolute;
        top: 0;
        bottom: 0;
        left: -1px;
        width: 2px;
        height: 100%;
      }
      &:first-child {
        &::before {
          display: none;
        }
      }
    }
  }
}
.tab-content__7 {
  .entry-content {
    padding-top: 35px;
  }
}
.tabs__text {
  padding: 0 130px 40px 130px!important;
  
  h3 {
	  margin-top: 50px;
  }
}
.col-50 {
  padding: 0 16px 16px 0!important;
}
@media (max-width: map-get($grid-breakpoints, md) - 1) {
  .tabs__content .tab-content__7 > div {
    display: block;
    opacity: 1;
  }
  .tabs__left {
    padding-bottom: 16px;
    padding-left: 0!important;
  }
  .tabs__text {
    padding-right: 0!important;
    padding-left: 0!important;
  }
}
@media (min-width: map-get($grid-breakpoints, md)) and (max-width: map-get($grid-breakpoints, lg) - 1) {
  .tabs__left {
    padding-left: 50px!important;
  }
  .tabs__text {
    padding: 0 50px!important;
  }
}
