#map {
  height: 330px;
}
.footer {
  color: $color-white;
  background-color: #212121;
  position: relative;
  overflow: hidden;
  &::before {
    // background: url("#{$root-images}footer-white.png") no-repeat 0 0;
    background: url("#{$root-images}footer-white.png") no-repeat center top;
    background-size: 100% auto;
    position: absolute;
    top: 0;
    // left: 50%;
    left: 0;
    z-index: 1;
    // width: 1920px;
    width: 100%;
    // height: 114px;
    height: 0;
    padding-bottom: percentage(114/1920);
    // margin-left: -960px;

    body.homepage & {
      content: "";
    }
  }
  &__content {
    color: #9d9d9c;
    font-size: r(15);
    line-height: 18px;
    padding-top: 130px;
    padding-bottom: 30px;
    p {
      margin-bottom: 0;
    }
    strong {
      color: $color-white;
      font-weight: 400;
      text-transform: uppercase;
    }
    a {
      color: #9d9d9c;
    }
  }
  .alt-footer{
    span{
      margin-left:21px;
      position:relative;
    }
    a{
      color:#fff;
      position:relative;
      margin-right:21px;
    }
  }
}
.bottom {
  background-color: $color-black;
  font-size: r(14);
  //font-size: r(10);
  padding: 25px 0;
}
